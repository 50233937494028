<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>应用设置</el-breadcrumb-item>
      <el-breadcrumb-item>公共数据</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :model="searchInfo" ref='formSearchInfoRef'>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item prop="type">
              <el-select v-model="searchInfo.type" @change="search" clearable @clear="search">
                <el-option v-for="item in options" :label="item.optionName" :value="item.optionNo" :key="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" plain @click='search'>搜索</el-button>
            <el-button type="info" plain @click='resetSearchInfo'>重置</el-button>
            <el-button type="success" v-if="$display('/static/add')" @click="addDataInfo">新增</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="pageInfo.listResult" border v-loading='loading'>
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column label="所属选项" prop="typeName" align="center"></el-table-column>
        <!-- <el-table-column label="所属类型" prop="type" align="center"></el-table-column> -->
        <el-table-column label="选项数值" prop="optionNo" align="center"></el-table-column>
        <el-table-column label="选项标题" prop="optionName" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope='scope'>
            <el-tooltip content="修改用户信息" placement="top-start" :enterable="false">
              <el-button type="primary" v-if="$display('/static/edit') && scope.row.disable == 1" icon="el-icon-edit" size="mini" @click='openEditDialog(scope.row)'></el-button>
            </el-tooltip>
            <el-tooltip content="删除用户信息" placement="top-start" :enterable="false">
              <el-popconfirm style='margin-left: 10px;' confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info" iconColor="red" title="确定删除这条记录吗？"
                @onConfirm="del(scope.row)">
                <el-button type="danger" v-if="$display('/static/del') && scope.row.disable == 1" slot="reference" icon="el-icon-delete" size="mini"></el-button>
                <!-- <el-button slot="reference" type="danger" icon="el-icon-delete" size="mini"></el-button> -->
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[1, 5, 10, 20, 30, 40, 100]"
        :page-size="searchInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :current-page.sync='defaultPage'
        :total="pageInfo.total">
      </el-pagination>
    </el-card>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" @close="closeDialog" width="30%">
      <el-form :model="dataInfo" ref='dialogDataInfoRef' :rules='rules'>
        <el-form-item prop="type" label="类型名称" :label-width="formLabelWidth">
          <el-select v-model="dataInfo.type" style="width: 80%;" clearable filterable :disabled="disable1">
            <el-option v-for="item in options" :label="item.optionName" :value="item.optionNo+''" :key="item.optionName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="optionName" label="选项名称" :label-width="formLabelWidth">
          <el-input v-model="dataInfo.optionName" placeholder="请输入选项名称" style="width: 80%;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveDatInfo" v-show="addButton">确 定</el-button>
        <el-button type="primary" @click="editDatInfo" v-show='editButton'>保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created() {
      this.getOptions();
      this.getList();
    },
    methods: {
      search(){
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.getList();
      },
      async del(rowData){
        var {data:res} = await this.$http.delete("/static/del/" + rowData.id);
        if(!res.success){
          this.$message.error(res.message);
          return;
        }
        this.getList();
        return this.$message.success("删除成功");
      },
      openEditDialog(rowData){
        this.dataInfo = JSON.parse(JSON.stringify(rowData));
        this.dialogTitle = "修改公共数据";
        this.editButton = true,
        this.addButton = false;
        this.disable1 = true;
        this.dialogVisible = true;
      },
      closeDialog() {
        this.$refs.dialogDataInfoRef.resetFields();
      },
      addDataInfo() {
        this.editButton = false,
        this.addButton = true;
        this.disable1 = false;
        this.dialogTitle = '新增公共数据';
        this.dialogVisible = true;
      },
      async editDatInfo(){
        var {data:res} = await this.$http.post("/static/edit", this.dataInfo);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success("修改成功");
        this.getList();
        this.dialogVisible = false;
      },
      async saveDatInfo() {
        var {
          data: res
        } = await this.$http.post("/static/add", this.dataInfo);
        if (!res.success) {
          this.$message.error(res.message);
        }
        this.getList();
        this.dialogVisible = false;
      },
      resetSearchInfo() {
        this.$refs.formSearchInfoRef.resetFields();
        this.getList();
      },
      async getList() {
        this.loading = true;
        var {
          data: res
        } = await this.$http.post("/static/list", this.searchInfo);
        this.loading = false;
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.pageInfo = res.result;
      },
      async getOptions() {
        var {
          data: res
        } = await this.$http.get("/static/values/static");
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.options = res.result;
      },
      handleSizeChange(val) {
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.searchInfo.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.searchInfo.page = val;
        this.getList();
      },
    },
    data() {
      return {
        loading:false,
        addButton:false,
        editButton:false,
        disable1:false,
        rules: {
          optionName: [{
            required: true,
            message: '选项名称不能为空',
            trigger: 'blur'
          }]
        },
        formLabelWidth: '120px',
        defaultPage: 1,
        pageInfo: {
          firstPage: false,
          lastPage: false,
          listResult: [],
          navigatepageNums: [],
          pageCount: 0,
          total: 0
        },
        dataInfo: {
          type: '',
          optionName: '',
          optionNo: 0,
          typeName:'',
          disable:0,
        },
        dialogVisible: false,
        dialogTitle: '',
        searchInfo: {
          type: '',
          page: 1,
          pageSize: 10
        },
        options: [],
      }
    }
  }
</script>

<style>
</style>
