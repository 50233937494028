<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>日常任务</el-breadcrumb-item>
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form ref='deviceManagementRef' :model="searchInfo">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="time">
              <el-date-picker type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd" v-model="searchInfo.time" style="width: 100%;">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-tooltip content="巡视人" placement="top" :enterable="false">
              <el-select clearable placeholder="巡视人" v-model="searchInfo.inspectionUser" filterable @change="search" @clear="search">
                <el-option v-for="item in Config.inspectorOptions" :key='item.staffNo' :label="item.name" :value="item.staffNo"></el-option>
              </el-select>
            </el-tooltip>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click='search' plain>搜索</el-button>
            <el-button type="success" v-if="$display('/device/management/add')" @click='openAddDialog'>新增</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table border :data="pageInfo.listResult" v-loading="loading">
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column label="日期" prop="timeStr" width="120px" align="center"></el-table-column>
        <el-table-column label="巡视人" prop="inspectionUser" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ Config.inspectorDisplay[scope.row.inspectionUser] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="捕获数量" prop="count" align="center"></el-table-column>
        <el-table-column label="设备情况" align="center">
          <el-table-column label="粘鸟网" prop="birdWeb" align="center">
            <template slot-scope='scope'>
              <el-tag :type="statusStr[scope.row.birdWeb].type">{{statusStr[scope.row.birdWeb].label}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="煤气炮" prop="gasGun" align="center">
            <template slot-scope='scope'>
              <el-tag :type="statusStr[scope.row.gasGun].type">{{statusStr[scope.row.gasGun].label}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="车载声波" prop="acoustic" align="center">
            <template slot-scope='scope'>
              <el-tag :type="statusStr[scope.row.acoustic].type">{{statusStr[scope.row.acoustic].label}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="车载煤气炮" width="120px" prop="carGasGun" align="center">
            <template slot-scope='scope'>
              <el-tag :type="statusStr[scope.row.carGasGun].type">{{statusStr[scope.row.carGasGun].label}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="彩带" prop="colourBar" align="center">
            <template slot-scope='scope'>
              <el-tag :type="statusStr[scope.row.colourBar].type">{{statusStr[scope.row.colourBar].label}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="鼠药盒" prop="rodenticide" align="center">
            <template slot-scope='scope'>
              <el-tag :type="statusStr[scope.row.rodenticide].type">{{statusStr[scope.row.rodenticide].label}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="障碍物" prop="obstacle" align="center">
            <template slot-scope='scope'>
              <el-tag v-if="scope.row.obstacle == true" type="danger">有</el-tag>
              <el-tag v-else type="success">无</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="障碍灯" prop="obstacleLight" align="center">
            <template slot-scope='scope'>
              <el-tag v-if="scope.row.obstacleLight == true" type="success">正常</el-tag>
              <el-tag type="danger" v-else>不亮</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="围界" prop="enclosing" align="center">
            <template slot-scope='scope'>
              <el-tag v-if="scope.row.enclosing == true" type="success">正常</el-tag>
              <el-tag type="danger" v-else>不亮</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="排水口" prop="outfall" align="center">
            <template slot-scope='scope'>
              <el-tag v-if="scope.row.outfall == true" type="success">正常</el-tag>
              <el-tag type="danger" v-else>空隙大</el-tag>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="操作" width="200px" align="center">
          <template slot-scope='scope'>
            <el-tooltip content="查看详情" placement="top" :enterable="false">
              <el-button type="success" icon="el-icon-notebook-2" size="mini" @click='openDetailDialog(scope.row)'></el-button>
            </el-tooltip>
            <el-tooltip content="修改用户信息" placement="top-start" :enterable="false">
              <el-button type="primary" v-if="$display('/device/management/edit')" icon="el-icon-edit" size="mini" @click='openEditDialog(scope.row)'></el-button>
            </el-tooltip>
            <el-tooltip content="删除用户信息" placement="top-start" :enterable="false">
              <el-popconfirm style='margin-left: 10px;' v-if='$display("/device/management/del")' confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info" iconColor="red" title="确定删除这条记录吗？"
                @onConfirm="delDeviceStatus(scope.row)">
                <el-button type="danger" slot="reference" icon="el-icon-delete" size="mini"></el-button>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[1, 5, 10, 20, 30, 40, 100]"
        :page-size="searchInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :current-page.sync='defaultPage' :total="pageInfo.total">
      </el-pagination>
    </el-card>
    <el-dialog :title="deviceDialogTitle" :visible.sync="deviceStatusAddVisible" @close="resetAddDeviceStatusDialLog">

      <el-form :model="deviceManagementInfo" ref='deviceManagementInfoRef' label-width="120px">
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="time" label="日期">
              <el-date-picker v-model="deviceManagementInfo.time" type="datetime" placeholder="选择日期时间" :disabled="fixedEnable" style="width: 100%;">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="inspectionUser" label="巡视人">
              <el-tooltip content="巡视人" placement="top" :enterable="false">
                <el-select clearable placeholder="巡视人" v-model="deviceManagementInfo.inspectionUser" filterable :disabled="fixedEnable">
                  <el-option v-for="item in Config.inspectorOptions" :key='item.staffNo' :label="item.name" :value="item.staffNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="birdWeb" label="粘鸟网">
              <el-select clearable placeholder="粘鸟网" v-model="deviceManagementInfo.birdWeb" filterable :disabled="modifiableEnable">
                <el-option v-for="item in statusList" :key='item.value' :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="gasGun" label="煤气炮">
              <el-select clearable placeholder="煤气炮" v-model="deviceManagementInfo.gasGun" filterable :disabled="modifiableEnable">
                <el-option v-for="item in statusList" :key='item.value' :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="birdWeb" label="车载声波">
              <el-select clearable placeholder="车载声波" v-model="deviceManagementInfo.acoustic" filterable :disabled="modifiableEnable">
                <el-option v-for="item in statusList" :key='item.value' :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="gasGun" label="车载煤气炮">
              <el-select clearable placeholder="车载煤气炮" v-model="deviceManagementInfo.carGasGun" filterable :disabled="modifiableEnable">
                <el-option v-for="item in statusList" :key='item.value' :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="birdWeb" label="彩带">
              <el-select clearable placeholder="彩带" v-model="deviceManagementInfo.colourBar" filterable :disabled="modifiableEnable">
                <el-option v-for="item in statusList" :key='item.value' :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="gasGun" label="鼠药盒">
              <el-select clearable placeholder="鼠药盒" v-model="deviceManagementInfo.rodenticide" filterable :disabled="modifiableEnable">
                <el-option v-for="item in statusList" :key='item.value' :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="obstacle" label="障碍物">
              <el-radio-group v-model="deviceManagementInfo.obstacle" size='mini' :disabled="modifiableEnable">
                <el-radio-button :label="true">有</el-radio-button>
                <el-radio-button :label="false">无</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="obstacleLight" label="障碍灯">
              <el-radio-group v-model="deviceManagementInfo.obstacleLight" size='mini' :disabled="modifiableEnable">
                <el-radio-button :label="true">正常</el-radio-button>
                <el-radio-button :label="false">不亮</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="enclosing" label="围界">
              <el-radio-group v-model="deviceManagementInfo.enclosing" size='mini' :disabled="modifiableEnable">
                <el-radio-button :label="true">正常</el-radio-button>
                <el-radio-button :label="false">不亮</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="outfall" label="排水口">
              <el-radio-group v-model="deviceManagementInfo.outfall" size='mini' :disabled="modifiableEnable">
                <el-radio-button :label="true">正常</el-radio-button>
                <el-radio-button :label="false">空隙大</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="count" label="捕获数量">
              <el-input placeholder="请输入捕获数量" v-model="deviceManagementInfo.count" clearable :disabled="modifiableEnable"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="memo" label="备注">
              <el-input placeholder="备注信息" v-model="deviceManagementInfo.memo" clearable :disabled="modifiableEnable"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="warehouseOutside" label="枪柜钥匙保管人">
              <el-input placeholder="请输入姓名" v-model="deviceManagementInfo.warehouseOutside" clearable :disabled="modifiableEnable"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="warehouseInside" label="弹柜钥匙保管人">
              <el-input placeholder="请输入姓名" v-model="deviceManagementInfo.warehouseInside" clearable :disabled="modifiableEnable"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="gunManager" label="枪支保管人">
              <el-input placeholder="请输入姓名" v-model="deviceManagementInfo.gunManager" clearable :disabled="modifiableEnable"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="ammunitionManager" label="弹药保管人">
              <el-input placeholder="请输入姓名" v-model="deviceManagementInfo.ammunitionManager" clearable :disabled="modifiableEnable"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deviceStatusAddVisible = false">取 消</el-button>
        <el-button type="primary" @click="deviceStatusAdd" v-show='addButton'>确 定</el-button>
         <el-button type="primary" @click="deviceStatusEdit" v-show='editButton' >确 定</el-button>
        <el-button type="primary" @click="change2Edit" v-show="updateButton">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created() {
      this.initTime();
      this.getList();
    },
    methods: {
      search(){
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.getList();
      },
      openAddDialog(){
        this.deviceDialogTitle = '设备状态新增';
        this.fixedEnable = false;
        this.modifiableEnable = false;
        this.updateButton = false;
        this.addButton = true;
        this.editButton = false;
        this.resetDialog();

        this.deviceStatusAddVisible = true;
      },
      openEditDialog(rowData){
        this.deviceDialogTitle = '设备状态修改';
        this.fixedEnable = true;
        this.modifiableEnable = false;
        this.updateButton = false;
        this.addButton = false;
        this.editButton = true;

        this.deviceManagementInfo = JSON.parse(JSON.stringify(rowData));
        this.deviceStatusAddVisible = true;
      },
      openDetailDialog(rowData){
        this.deviceDialogTitle = '设备状态详情';
        this.fixedEnable = true;
        this.modifiableEnable = true;
        this.updateButton = true;
        this.addButton = false;
        this.editButton = false;

        this.deviceManagementInfo = JSON.parse(JSON.stringify(rowData));
        this.deviceStatusAddVisible = true;
      },
      async getList() {
        this.loading = true;
        if(this.searchInfo.time.length == 2){
          this.searchInfo.time[0] = this.moment(this.searchInfo.time[0]).format('YYYY-MM-DD HH:mm:ss');
          this.searchInfo.time[1] = this.moment(this.searchInfo.time[1]).format('YYYY-MM-DD HH:mm:ss');
        }
        var {
          data: res
        } = await this.$http.post("/device/management/list", this.searchInfo);
        this.loading = false;
        if ("SUCCESS" != res.code) {
          this.$message.error(res.message);
          return;
        }
        this.pageInfo = res.result;
      },
      resetAddDeviceStatusDialLog() {
        this.$refs.deviceManagementInfoRef.resetFields();
      },
      change2Edit(){
        // 切换为修改状态
        this.updateButton = false;
        this.deviceDialogTitle = '设备状态修改';
        this.fixedEnable = true;
        this.editButton = true;
        this.modifiableEnable = false;
      },
      async delDeviceStatus(rowData){
        var {
          data: res
        } = await this.$http.delete("/device/management/del/" + rowData.id);

        if ("SUCCESS" != res.code) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success("数据删除成功");
        this.getList();
      },
      async deviceStatusEdit(){
        var {
          data: res
        } = await this.$http.post("/device/management/edit", this.deviceManagementInfo);

        if ("SUCCESS" != res.code) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success("数据修改成功");
        this.deviceStatusAddVisible = false;
        this.getList();
      },
      async deviceStatusAdd() {
        var t = this.deviceManagementInfo.time;
        this.deviceManagementInfo.time = this.moment(t).format('YYYY-MM-DD HH:mm:ss')
        var {
          data: res
        } = await this.$http.post("/device/management/add", this.deviceManagementInfo);
        if ("SUCCESS" != res.code) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success("数据添加成功");
        this.deviceStatusAddVisible = false;
        this.getList();
      },
      resetSearch() {
        this.$refs.deviceManagementRef.resetFields();
      },
      initTime() {
        var date = new Date();
        this.searchInfo.time[0] = new Date(date.setDate(date.getDate() - 7));
        this.searchInfo.time[1] = new Date();
      },
      handleSizeChange(val) {
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.searchInfo.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.searchInfo.page = val;
        this.getList();
      },
      resetDialog(){
        this.deviceManagementInfo = {
          time: new Date(),
          inspectionUser: '',
          birdWeb: 1,
          gasGun: 1,
          acoustic: 1,
          carGasGun: 1,
          colourBar: 1,
          rodenticide: 1,
          count: 0,
          memo: '',
          obstacle: false,
          obstacleLight: true,
          enclosing: true,
          outfall: true,
          warehouseOutside: '',
          warehouseInside: '',
          gunManager: '',
          ammunitionManager: ''
        };
      }
    },
    data() {
      return {
        loading:false,
        deviceDialogTitle:"",
        deviceStatusAddVisible: false,
        addButton:false,
        editButton:false,
        updateButton:false,
        searchInfo: {
          time: [],
          inspectionUser: '',
          page:1,
          pageSize:10
        },
        pageInfo: {
          firstPage: false,
          lastPage: false,
          listResult: [],
          navigatepageNums: [],
          pageCount: 0,
          total: 0
        },
        defaultPage:1,
        deviceStatusList: [],
        updateDialog:1,
        fixedEnable:false,
        modifiableEnable:false,
        deviceManagementInfo: {
          time: new Date(),
          inspectionUser: '',
          birdWeb: 1,
          gasGun: 1,
          acoustic: 1,
          carGasGun: 1,
          colourBar: 1,
          rodenticide: 1,
          count: 0,
          memo: '',
          obstacle: false,
          obstacleLight: true,
          enclosing: true,
          outfall: true,
          warehouseOutside: '',
          warehouseInside: '',
          gunManager: '',
          ammunitionManager: '',
          id:''
        },
        statusList: [{
            value: 1,
            label: "完好"
          },
          {
            value: 2,
            label: "部分破损"
          },
          {
            value: 3,
            label: "正在维护"
          },
          {
            value: 4,
            label: "维修完毕"
          }
        ],
        statusStr:{
          0:{label:'无', type:'success'},
          1:{label:'完好', type:'success'},
          2:{label:'故障', type:'danger'},
          3:{label:'正在维护', type:'warning'},
          4:{label:'维修完毕', type:'success'},
        },
        operatorList: [{
            value: 1,
            label: "巡视人1"
          },
          {
            value: 2,
            label: "巡视人2"
          },
          {
            value: 3,
            label: "巡视人3"
          },
          {
            value: 4,
            label: "巡视人4"
          },
          {
            value: 5,
            label: "巡视人5"
          },
        ],
      }
    }
  }
</script>

<style>
</style>
