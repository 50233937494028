<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>物种百科</el-breadcrumb-item>
      <el-breadcrumb-item>昆虫信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
          <el-form :model="searchInfo">
            <el-row :gutter="20">
              <el-col :span="4">
                <el-form-item prop="name">
                  <el-input placeholder="请输入首字母或名字" v-model="searchInfo.name" clearable @clear="search"
                    @keyup.enter.native='search'>
                    <el-button slot="append" icon="el-icon-search" @click='search'></el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="17">
                <el-button type="primary" v-if="$display('/wiki/insect/add')" @click="openAddDialog">新增昆虫</el-button>
              </el-col>
            </el-row>
          </el-form>
          <el-table :data="pageInfo.listResult" style="width: 100%" border v-loading="loading">
            <el-table-column type="index" label="#" align="center"></el-table-column>
            <el-table-column prop="pic" label="图片" align="center" width="80px">
              <template slot-scope='scope'>
                <el-tooltip content="点击查看图片" placement="top" :enterable="false">
                  <el-image :src="scope.row.pic" :preview-src-list="scope.row.picList" alt>
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="名称" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center" width="270px">
              <template slot-scope='scope'>
                <el-tooltip content="查看详情" placement="top" :enterable="false">
                  <el-button type="success" icon="el-icon-notebook-2" size="mini" @click='getInsectInfo(scope.row)'></el-button>
                </el-tooltip>
                <el-tooltip content="编辑" placement="top" :enterable="false">
                  <el-button type="primary" v-if="$display('/wiki/insect/edit')" icon="el-icon-edit" size="mini" @click='editInsectInfo(scope.row)'></el-button>
                </el-tooltip>
                <el-popconfirm style='margin-left: 10px; margin-right: 10px;' confirmButtonText='确定' cancelButtonText='取消'
                  icon="el-icon-info" iconColor="red" title="确定删除这条记录吗？" @onConfirm="deleteInfo(scope.row)">
                  <el-button slot="reference" v-if="$display('/wiki/insect/del')" type="danger" icon="el-icon-delete" size="mini"></el-button>
                </el-popconfirm>
                <el-tooltip content="上传照片" placement="top" :enterable="false">
                  <el-button type="primary" v-if="$display('/wiki/insect/add') || $display('/wiki/insect/edit')" @click='openUploadDialog(scope.row)' size="mini"><i class="el-icon-upload el-icon--right"></i></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[1, 5, 10, 20, 30, 40, 100]"
            :page-size="searchInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :current-page.sync='defaultPage' :total="pageInfo.total">
          </el-pagination>
        </el-card>
        <el-dialog :title="dialogTitle" :visible.sync="dialogvisble" @close="closeDialog">
          <el-form :model="insectInfo" ref='InsectInfoRef' :rules='insectInfoRules'>
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="name" label="名称" :label-width="formLabelWidth">
                  <el-input v-model.trim="insectInfo.name" placeholder="请输入昆虫全名" clearable :disabled="enable1"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item prop="relationBirdNo" label="关联鸟类" :label-width="formLabelWidth">
                  <el-select v-model="insectInfo.relationBirdNoList" filterable placeholder="请选择" clearable multiple
                    :disabled="enable2">
                    <el-option v-for="item in Config.birdOptions" :key="item.birdNo" :label="item.name" :value="item.birdNo">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="23">
                <el-form-item prop="aliasList" label="俗称" :label-width="formLabelWidth">
                  <el-tag :key="tag" v-for="tag in insectInfo.aliasList" :closable="!enable2" :disable-transitions="false"
                    @close="handleClose(tag)">
                    {{tag}}
                  </el-tag>
                  <el-input :disabled="enable2" style="width:80px;" class="input-new-tag" v-if="inputVisible" v-model="inputValue"
                    ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                  </el-input>
                  <el-button :disabled="enable2" v-else class="button-new-tag" size="small" :style="inputMarginleft" @click="showInput">+
                    俗称</el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="22">
                <el-form-item prop="features" label="简介" :label-width="formLabelWidth">
                  <el-input :disabled="enable2" type="textarea" :rows='5' placeholder="请输入简介,长度不超过300字" v-model="insectInfo.features"
                    maxlength="300" show-word-limit clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogvisble = false">取 消</el-button>
            <el-button type="primary" @click="saveInsectInfo" v-show="addButton">确 定</el-button>
            <el-button type="primary" @click="editDialog" v-show="editButton">修 改</el-button>
            <el-button type="primary" @click="updateInsectInfo" v-show="updateButton">保 存</el-button>
          </div>
        </el-dialog>

        <el-dialog title="上传图片" :visible.sync="uploadDialogVisible" width="30%">
          <el-upload class="upload-demo" :action="Config.INSECT_UPLOAD_URL + insectInfo.insectNo" :headers="Config.httpHeader"
            :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="insectInfo.imagesInfos" :on-success='uploadCallback'
            pre list-type="picture-card" accept="image/jpeg, image/png" :before-upload="beforeAvatarUpload" multiple :disabled="enable2">
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5MB</div>
          </el-upload>
          <span slot="footer" class="dialog-footer">
            <el-button @click="uploadDialogVisible = false">取 消</el-button>
          </span>
        </el-dialog>

        <el-dialog :visible.sync="imgDialogvisble">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </div>
    </template>

    <script>
      export default {
        created() {
          this.getList();
        },
        methods: {
          search(){
            this.searchInfo.page = 1;
            this.defaultPage = 1;
            this.getList();
          },
          handleSizeChange(val) {
            this.searchInfo.page = 1;
            this.defaultPage = 1;
            this.searchInfo.pageSize = val;
            this.getList();
          },
          handleCurrentChange(val) {
            this.searchInfo.page = val;
            this.getList();
          },
          getInsectInfo(rowData) {
            this.insectInfo = JSON.parse(JSON.stringify(rowData));
            this.dialogTitle = "昆虫详情";
            this.enable1 = true;
            this.enable2 = true;
            this.addButton = false;
            this.editButton = true;
            this.updateButton = false;
            this.dialogvisble = true;
          },

          async deleteInfo(rowData){
            var {
              data: res
            } = await this.$http.delete("/wiki/insect/del/" + rowData.insectNo);
            if ("SUCCESS" != res.code) {
              this.$message.error(res.message);
              return;
            }
            this.$message.success("删除成功");
            this.getList();
          },
          async saveInsectInfo() {

            var {
              data: res
            } = await this.$http.post("/wiki/insect/add", this.insectInfo);
            if ("SUCCESS" != res.code) {
              this.$message.error(res.message);
              return;
            }
            this.$message.success("新增成功");
            this.getList();
            this.dialogvisble = false;
          },
          editDialog() {
            this.dialogTitle = "修改昆虫信息";
            this.enable1 = true;
            this.enable2 = false;
            this.addButton = false;
            this.editButton = false;
            this.updateButton = true;
            this.dialogvisble = true;
          },
          editInsectInfo(rowData){
            this.insectInfo = JSON.parse(JSON.stringify(rowData));
            this.editDialog();
          },
          async updateInsectInfo() {

            var {
              data: res
            } = await this.$http.post("/wiki/insect/edit", this.insectInfo);
            if ("SUCCESS" != res.code) {
              this.$message.error(res.message);
              return;
            }
            this.$message.success("修改成功");
            this.getList();
            this.dialogvisble = false;
          },
          handleClose(tag) {
            this.insectInfo.aliasList.splice(this.insectInfo.aliasList.indexOf(tag), 1);
          },
          showInput() {
            this.inputVisible = true;
            this.$nextTick(_ => {
              this.$refs.saveTagInput.$refs.input.focus();
            });
          },

          handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue && this.insectInfo.aliasList.indexOf(inputValue) == -1) {
              this.insectInfo.aliasList.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
          },
          openAddDialog() {
            this.dialogTitle = "新增昆虫";
            this.enable1 = false;
            this.enable2 = false;
            this.addButton = true;
            this.editButton = false;
            this.updateButton = false;
            this.dialogvisble = true;
          },
          closeDialog() {
            this.$refs.InsectInfoRef.resetFields();
          },
          async getList() {
            this.loading = true;
            var {
              data: res
            } = await this.$http.post("/wiki/insect/list", this.searchInfo);
            this.loading = false;
            if (!res.success) {
              this.$message.error(res.message);
              return;
            }
            this.pageInfo = res.result;
          },
          async handleRemove(file) {
            var {
              data: res
            } = await this.$http.delete("/upload/del/" + file.id);
            if (!res.success) {
              this.$message.error(res.message);
              this.birdInfo.imagesInfos.push(file);
              return;
            }
            this.$message.success("删除图片成功");
            this.getList();
          },
          handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.imgDialogvisble = true;
          },
          uploadCallback(response, file, fileList) {
            if (!response.success) {
              this.$message.error(response.message);
              return;
            }
            file.id = response.result.id;
            this.getList();
          },
          beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 5;

            if (!isJPG) {
              this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
            }
            if (!isLt2M) {
              this.$message.error('上传头像图片大小不能超过 5MB!');
            }
            return isJPG && isLt2M;
          },
          openUploadDialog(rowData) {
            this.enable2 = false;
            this.insectInfo = JSON.parse(JSON.stringify(rowData));
            this.uploadDialogVisible = true;
          },
        },
        data() {
          return {
            dialogImageUrl: '',
            imgDialogvisble: false,
            uploadDialogVisible:false,
            loading:false,
            pageInfo: {
              firstPage: false,
              lastPage: false,
              listResult: [],
              navigatepageNums: [],
              pageCount: 0,
              total: 0
            },
            defaultPage:1,
            inputValue: '',
            addButton: false,
            editButton: false,
            updateButton: false,
            enable1: false,
            enable2: false,
            inputVisible: false,
            formLabelWidth: "120px",
            dialogvisble: false,
            dialogTitle: '',
            inputMarginleft: "margin-left:10px",
            insectInfo: {
              insectNo:'',
              name: '',
              aliasList: [],
              relationBirdNoList: [],
              features: '',
              pic: '',
              picList: [],
              imagesInfos: []
            },
            insectInfoList: [],
            insectInfoRules: {
              name: [{
                required: true,
                message: '名字不能为空',
                trigger: 'blur'
              }],
              features: [{
                required: true,
                message: "简介不能为空",
                trigger: 'blur'
              }]
            },
            searchInfo: {
              name: '',
              page:1,
              pageSize:10
            },
            options: [{
                value: 1,
                label: "鸟类1"
              },
              {
                value: 2,
                label: "鸟类2"
              },
              {
                value: 3,
                label: "鸟类3"
              },
              {
                value: 4,
                label: "鸟类4"
              },
            ]
          }
        }
      }
    </script>

    <style>
    </style>
