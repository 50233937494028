import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginRouter from '../components/login.vue'
import HomeRouter from '../components/home.vue'
import UserListRouter from '../components/user/user_list.vue'
import WelcomeRouter from '../components/welcome.vue'
import RepelBirdsRouter from '../components/daily/repel_bird.vue'
import PickBirdsRouter from '../components/daily/pick_birds.vue'
import DeviceRouter from '../components/daily/device.vue'
import BirdInfoRouter from '../components/wiki/bird_info.vue'
import BeastInfoRouter from '../components/wiki/beast_info.vue'
import InsectInfoRouter from '../components/wiki/insect_info.vue'
import AnimalinfoRouter from '../components/wiki/animal_info.vue'
import BotanyInfoRouter from '../components/wiki/botany_info.vue'
import BirdStrikeRouter from '../components/event/bird_strike.vue'
import RodenticideRouter from '../components/daily/rodenticide.vue'
import SprayingMedicineRouter from '../components/daily/sprayingMedicine.vue'
import PermissionListRouter from '../components/user/permission_list.vue'
import PermissionSettingsRouter from '../components/user/permission_settings.vue'
import StaticDataRouter from '../components/static/static_data.vue'
import InsectAnalyzeRouter from '../components/hardware/insect_analyze.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/login'
}, {
  path: '/login',
  component: LoginRouter
}, {
  path: '/home',
  component: HomeRouter,
  redirect: '/welcome',
  children: [{
      path: '/welcome',
      component: WelcomeRouter
    },
    {
      path: '/static/list',
      component: StaticDataRouter
    },
    {
      path: '/staff/list',
      component: UserListRouter
    }, {
      path: '/permission/list',
      component: PermissionListRouter
    },
    {
      path: '/role/list',
      component: PermissionSettingsRouter
    },
    {
      path: '/repelBirds/list',
      component: RepelBirdsRouter
    },
    {
      path: '/pickBirds/list',
      component: PickBirdsRouter
    },
    {
      path: '/device/management/list',
      component: DeviceRouter
    },
    {
      path: '/rodenticide/list',
      component: RodenticideRouter
    },
    {
      path: '/sprayingMedicine/list',
      component: SprayingMedicineRouter
    },
    {
      path: '/wiki/bird/list',
      component: BirdInfoRouter
    },
    {
      path: '/wiki/beast/list',
      component: BeastInfoRouter
    },
    {
      path: '/wiki/insect/list',
      component: InsectInfoRouter
    },
    {
      path: '/wiki/animal/list',
      component: AnimalinfoRouter
    }, {
      path: '/wiki/botany/list',
      component: BotanyInfoRouter
    }, {
      path: '/birdStrike/list',
      component: BirdStrikeRouter
    },
    {
      path: '/device/insect/list',
      component: InsectAnalyzeRouter
    }
  ]
}]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {

  var token = window.localStorage.getItem("token");
  if (to.path === '/login' && (null != token && "" != token)) {
    return next('/home')
  }
  if (to.path === '/login') {
    return next();
  }
  if (!token) return next('/login');
  return next();
})
export default router
