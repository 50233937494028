<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :model="searchInfo" ref='searchInfoRef'>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item prop="name">
              <el-tooltip content="权限名称" placement="top" :enterable="false">
                <el-input v-model="searchInfo.name" placeholder="请输入权限名称" clearable @clear="search" @keyup.enter.native='search'></el-input>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="parentNo">
              <el-tooltip content="顶级权限" placement="top" :enterable="false">
                <el-select v-model="searchInfo.parentNo" placeholder="顶级权限" clearable filterable @clear="search" @change="search">
                  <el-option v-for="item in parentOptions" :label="item.name" :key="item.no" :value="item.no"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="type">
              <el-tooltip content="权限类型" placement="top" :enterable="false">
                <el-select v-model="searchInfo.type" placeholder="权限类型" clearable filterable @clear="search" @change="search">
                  <el-option v-for="item in typeOptions" :label="item.label" :key="item.label" :value="item.value"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" plain @click='search'>搜索</el-button>
            <el-button type="info" plain @click='resetSearchInfo'>重置</el-button>
            <el-button type="success" v-if="$display('/permission/add')" @click="openAddDialog">新增</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="pageInfo.listResult" border v-loading='loading'>
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column prop="name" label="名称" align="center">
          <template slot-scope='scope'>
            <el-tag>{{scope.row.name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="path" label="路径" align="center"></el-table-column>
        <el-table-column prop="parentNo" label="父类路径" align="center">
          <template slot-scope='scope'>
            <el-tag v-if="'0' === scope.row.parentNo" type="success">顶级</el-tag>
            <el-tag v-else>{{ scope.row.parentName}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" align="center">
          <template slot-scope='scope'>
            <el-tag v-if="1 == scope.row.type" type="success">列表</el-tag>
            <el-tag v-else>按钮</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="顺序" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope='scope'>
            <el-tooltip content="编辑" placement="top" :enterable="false">
              <el-button type="primary" v-if="$display('/permission/edit')" icon="el-icon-edit" size="mini" @click='openEditDataInfo(scope.row)'></el-button>
            </el-tooltip>
            <el-popconfirm style='margin-left: 10px; margin-right: 10px;' confirmButtonText='确定' cancelButtonText='取消'
              icon="el-icon-info" iconColor="red" title="确定删除这条记录吗？" @onConfirm="delDataInfo(scope.row)">
              <el-button slot="reference" v-if="$display('/permission/del')" type="danger" icon="el-icon-delete" size="mini"></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[1, 5, 10, 20, 30, 40, 100]"
        :page-size="searchInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :current-page.sync='defaultPage' :total="pageInfo.total">
      </el-pagination>
    </el-card>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" @close="closeDialog">
      <el-form :model="dataInfo" ref='dataInfoRef'>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="name" label="名称" :label-width="formLabelWidth">
              <el-input v-model="dataInfo.name" placeholder="请输入名称" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="path" label="路径" :label-width="formLabelWidth">
              <el-input v-model="dataInfo.path" placeholder="请输入路径" clearable :disabled="isParent"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="isParent" label="顶级权限" :label-width="formLabelWidth">
              <el-radio-group v-model="isParent" size="mini">
                <el-radio-button :label="true">是</el-radio-button>
                <el-radio-button :label="false">否</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="parentNo" label="父权限" :label-width="formLabelWidth">
              <el-tooltip content="顶级权限" placement="top" :enterable="false">
                <el-select v-model="dataInfo.parentNo" placeholder="顶级权限" clearable filterable :disabled="isParent">
                  <el-option v-for="item in parentOptions" :label="item.name" :key="item.no" :value="item.no"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="type" label="权限类型" :label-width="formLabelWidth">
              <el-radio-group v-model="dataInfo.type" size="mini">
                <el-radio-button :label="1">列表</el-radio-button>
                <el-radio-button :label="2">按钮</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="sort" label="顺序" :label-width="formLabelWidth">
              <el-tooltip content="不填写默认顺序排列" placement="top" :enterable="false">
                <el-input v-model="dataInfo.sort" placeholder="不填写默认顺序排列"></el-input>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveDataInfo" v-show='saveButton'>确 定</el-button>
        <el-button type="primary" @click="editDataInfo" v-show='editButton'>保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created() {
      this.getList();
    },
    methods: {
      search(){
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.getList();
      },
      resetSearchInfo(){
        this.$refs.searchInfoRef.resetFields();
        this.getList();
      },
      getDataInfo(rowData) {
        this.dataInfo = JSON.parse(JSON.stringify(rowData));
        this.afterHandler(rowData);
      },
      afterHandler(rowData) {
        if ('0' == rowData.parentNo)
          this.isParent = true;
        this.dialogVisible = true;
      },
      handleSizeChange(val) {
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.searchInfo.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.searchInfo.page = val;
        this.getList();
      },
      async editDataInfo() {
        if (this.isParent)
          this.dataInfo.parentNo = 0;
        var {
          data: res
        } = await this.$http.post("/permission/edit", this.dataInfo);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success("修改成功");
        this.getList();
        this.dialogVisible = false;
      },
      openEditDataInfo(rowData) {
        this.dataInfo = JSON.parse(JSON.stringify(rowData));
        this.dialogTitle = "修改权限信息";
        this.editButton = true;
        this.saveButton = false;
        this.afterHandler(rowData);
      },
      async delDataInfo(rowData) {
        var {
          data: res
        } = await this.$http.delete("/permission/del/" + rowData.id);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success("删除成功");
        this.getList();
      },
      closeDialog() {
        this.isParent = false;
        this.$refs.dataInfoRef.resetFields();
      },
      async saveDataInfo() {

        if (this.isParent)
          this.dataInfo.parentNo = 0;
        var {
          data: res
        } = await this.$http.post("/permission/add", this.dataInfo);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success("添加成功");
        this.getList();
        this.dialogVisible = false;
      },
      openAddDialog() {
        this.dialogTitle = "新增权限信息";
        this.editButton = false;
        this.saveButton = true;
        this.dialogVisible = true;
      },
      async getParentOptions() {
        var {
          data: res
        } = await this.$http.get("/permission/options/json");
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.parentOptions = res.result;
      },
      async getList() {
        this.loading = true;
        var {
          data: res
        } = await this.$http.post("/permission/list", this.searchInfo);
        this.loading = false;
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.pageInfo = res.result;
        this.getParentOptions();
      }
    },
    data() {
      return {
        defaultPage:1,
        loading: false,
        updateStatusButton: false,
        saveButton: false,
        editButton: false,
        disable1: false,
        disable2: false,
        formLabelWidth: '120px',
        dialogVisible: false,
        dialogTitle: '',
        parentOptions: [],
        pageInfo: {
          firstPage: false,
          lastPage: false,
          listResult: [],
          navigatepageNums: [],
          pageCount: 0,
          total: 0
        },
        isParent: false,
        dataInfo: {
          id: 0,
          no: '',
          name: '',
          path: '',
          parentNo: '',
          parentName: '',
          type: 1,
          sort: 0,
          children: []
        },
        typeOptions: [{
            label: '列表',
            value: 1
          },
          {
            label: '按钮',
            value: 2
          }
        ],
        searchInfo: {
          name: '',
          parentNo: '',
          type: '',
          page: 1,
          pageSize: 10
        }
      }
    }
  }
</script>

<style>
</style>
