<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>日常任务</el-breadcrumb-item>
      <el-breadcrumb-item>网上摘鸟</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :model="searchInfo">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="time">
              <el-date-picker type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd" v-model="searchInfo.time" style="width: 100%;">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="inspectionUser">
              <el-tooltip content="巡视人" placement="top" :enterable="false">
                <el-select clearable placeholder="巡视人" v-model="searchInfo.inspectionUser" filterable @clear="search"
                  @change="search">
                  <el-option v-for="item in Config.inspectorOptions" :key='item.staffNo' :label="item.name"
                    :value="item.staffNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click='search' plain>搜索</el-button>
            <el-button type="success" v-if="$display('/pickBirds/add')" @click='addPickBirdInfo'>新增</el-button>
            <el-button type="infor" plain @click='exportExcel'>导出</el-button>
          </el-col>
        </el-row>
      </el-form>

      <el-table :data="pageInfo.listResult" border v-loading='loading'>
        <el-table-column label="天气" prop="weather" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ Config.weatherDisplay['dp' + scope.row.weather] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="日期" prop="timeStr" align="center"></el-table-column>
        <el-table-column label="巡视人" prop="inspectionUser" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ Config.inspectorDisplay[scope.row.inspectionUser] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="鸟网编号" prop="pedestalNo" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ Config.pedestaDisplay['dp' + scope.row.pedestalNo] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="鸟网位置" prop="location" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ scope.row.location }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="鸟种" prop="birdNo" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ Config.birdDisplay[scope.row.birdNo] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="数量" prop="count" align="center"></el-table-column>
        <el-table-column label="处置情况" prop="treatment" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ Config.treatmentDisplay['dp' + scope.row.treatment]}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70px" align="center">
          <template slot-scope='scope'>
            <el-popconfirm confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info" iconColor="red"
              title="确定删除这条记录吗？" @onConfirm="deletePickBirds(scope.row)">
              <el-button slot="reference" v-if="$display('/pickBirds/del')" type="danger" icon="el-icon-delete"
                size="mini"></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :page-sizes="[1, 5, 10, 20, 30, 40, 100]" :page-size="searchInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :current-page.sync='defaultPage' :total="pageInfo.total">
      </el-pagination>
    </el-card>
    <el-dialog :visible.sync="addPickBirdDialogVisible" title="增加摘鸟信息" @close="closeAddDiaLog">
      <el-form :model="pickBirdsInfo" label-position="right" :label-width="fromLabelWidth" ref='pickBirdInfoRef'>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="天气" :label-width="formLabelWidth">
              <el-select clearable placeholder="天气" filterable v-model="pickBirdsInfo.weather">
                <el-option v-for="item in Config.weatherOptions" :key='item.optionNo' :label="item.optionName"
                  :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="时间" prop="time">
              <el-date-picker v-model="pickBirdsInfo.time" type="datetime" placeholder="选择日期时间" style="width: 100%;">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">

          <el-col :span="11">
            <el-form-item label="鸟网编号" prop="pedestalNo">
              <el-select clearable placeholder="鸟网编号" filterable v-model="pickBirdsInfo.pedestalNo">
                <el-option v-for="item in Config.pedestaOptions" :key='item.optionNo' :label="item.optionName"
                  :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="11">
            <el-form-item label="挂网位置" prop="pedestalNo">
              <el-select clearable placeholder="挂网位置" filterable v-model="pickBirdsInfo.location">
                <el-option label="上" value="上"></el-option>
                <el-option label="中" value="中"></el-option>
                <el-option label="下" value="下"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">

          <el-col :span="11">
            <el-form-item label="鸟种" prop="birdNo">
              <el-select clearable placeholder="鸟种" filterable v-model="pickBirdsInfo.birdNo">
                <el-option v-for="item in Config.birdOptions" :key='item.birdNo' :label="item.name"
                  :value="item.birdNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="数量" prop="count" label-width="120px">
              <el-input placeholder="输入网上摘鸟的数量" v-model="pickBirdsInfo.count" clearable=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="处置情况" prop="treatment">
              <el-select clearable placeholder="处置情况" filterable v-model="pickBirdsInfo.treatment">
                <el-option v-for="item in Config.treatmentOptions" :key='item.optionNo' :label="item.optionName"
                  :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="巡视人" prop="inspectionUser">
              <el-select clearable placeholder="巡视人" v-model="pickBirdsInfo.inspectionUser" filterable
                style="width: 100%;">
                <el-option v-for="item in Config.inspectorOptions" :key='item.staffNo' :label="item.name"
                  :value="item.staffNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addPickBirdDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="savePickBirdsInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    export_json_to_excel
  } from '../../plugins/Export2Excel.js'
  export default {
    created() {
      this.initTime();
      this.getList();
    },
    methods: {
      async exportExcel() {
        this.tempPage = this.searchInfo.page;
        this.tempPageSize = this.searchInfo.pageSize;
        this.searchInfo.page = 1;
        this.searchInfo.pageSize = 10000;
        console.log(this.searchInfo);
        var {
          data: res
        } = await this.$http.post("/pickBirds/list", this.searchInfo);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.searchInfo.page = 1;
        this.searchInfo.pageSize = this.tempPageSize;
        this.search();
        this.getExcel(res.result.listResult);
      },
      getExcel(res) {
        const tHeader = ['日期', '天气', '巡视人', '鸟网编号', '挂网位置', '鸟种', '数量']
        const filterVal = ['timeStr', 'weather', 'inspectionUserName', 'pedestalName', 'location', 'birdName', 'count']
        const list = res
        const data = this.formatJson(filterVal, list)
        export_json_to_excel(tHeader, data, '鸟网摘鸟_' + this.searchInfo.time[0] + '_' + this.searchInfo.time[1], 'sheet1')
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => {
          return filterVal.map(j => {
            if (j == 'images' || j == 'resultImages')
              return v[j][0]['url'];
            if (j == 'weather') {
              if (!isNaN(v[j])) {
                var ind = v[j];
                return this.Config.weatherDisplay['dp' + ind];
              }
            }

            return v[j];
          })
        })
      },
      search() {
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.getList();
      },
      async deletePickBirds(rowData) {
        var {
          data: res
        } = await this.$http.delete("/pickBirds/del/" + rowData.id);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success("删除成功!");
        this.getList();
      },
      closeAddDiaLog() {
        this.$refs.pickBirdInfoRef.resetFields();
      },
      initTime() {
        var date = new Date();
        this.searchInfo.time[0] = new Date(date.setDate(date.getDate() - 7));
        this.searchInfo.time[1] = new Date();
      },
      addPickBirdInfo() {
        this.addPickBirdDialogVisible = true;
      },
      handleSizeChange(val) {
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.searchInfo.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.searchInfo.page = val;
        this.getList();
      },
      async savePickBirdsInfo() {
        if (this.pickBirdsInfo.time != null) {
          var t = this.pickBirdsInfo.time;
          t.setHours(t.getHours() - 8)
          this.pickBirdsInfo.time = this.moment(t).format('YYYY-MM-DD HH:mm:ss')
        }
        var {
          data: res
        } = await this.$http.post("/pickBirds/add", this.pickBirdsInfo);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success("添加成功");
        this.getList();
        this.addPickBirdDialogVisible = false;
      },
      async getList() {
        this.loading = true;
        if (this.searchInfo.time.length == 2) {
          this.searchInfo.time[0] = this.moment(this.searchInfo.time[0]).format('YYYY-MM-DD HH:mm:ss');
          this.searchInfo.time[1] = this.moment(this.searchInfo.time[1]).format('YYYY-MM-DD HH:mm:ss');
        }
        var {
          data: res
        } = await this.$http.post("/pickBirds/list", this.searchInfo);
        this.loading = false;
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.pageInfo = res.result;
      },
    },
    data() {
      return {
        loading: false,
        fromLabelWidth: "120px",
        addPickBirdDialogVisible: false,
        defaultPage: 1,
        tempPage: 1,
        tempPageSize: 10,
        pageInfo: {
          firstPage: false,
          lastPage: false,
          listResult: [],
          navigatepageNums: [],
          pageCount: 0,
          total: 0
        },
        birdList: [{
            value: 1,
            label: "麻雀"
          },
          {
            value: 2,
            label: "鹫类"
          },
          {
            value: 3,
            label: "鹤类"
          }
        ],
        pickBirdsList: [],
        searchInfo: {
          time: [],
          inspectionUser: '',
          page: 1,
          pageSize: 10
        },
        pickBirdsInfo: {
          time: new Date(),
          pedestalNo: '',
          birdNo: '',
          count: '',
          treatment: '',
          inspectionUser: '',
          inspectionUserName: '',
          pedestalName: '',
          weather: '',
          location: '',
          birdName: ''
        }
      };
    }
  }
</script>

<style>
</style>
