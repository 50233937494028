import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
import axios from 'axios'
import { Message } from 'element-ui'
import Config from './config.js'
import vueEsign from 'vue-esign'
import "./assets/icon_font/iconfont.css"
import Util from './plugins/util.js'
import Moment from 'moment'

Vue.prototype.Config = Config;
Vue.use(vueEsign);
Vue.use(Util);

axios.defaults.baseURL = Config.ROOT_URL;
axios.interceptors.request.use(config => {
  config.headers.token = window.localStorage.getItem('token');
  return config;
})

axios.interceptors.response.use(response =>{
  if("403" == response.data.code){
    window.localStorage.clear();
    Message.error('登录已过期，请重新登录！');
    router.push("/login");
    return;
  }

	return response;
}, error =>{
   Message.error('服务正在发布，请稍后重试！');
   return;
})
Vue.prototype.moment = Moment
Vue.prototype.$http = axios
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
