<template>
  <div style="padding: 30px;">
    <el-row>
      <el-col :span="7">
        <el-row>
          <el-col :span="5">
            <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 24 24" width="76" height="76" style="fill: rgb(0, 122, 255);">
              <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"></path>
            </svg>
          </el-col>
          <el-col :span="16" class='card_right_info'>
            <div>今日驱赶 <el-tooltip content="今日驱鸟工作单驱鸟数量"><i class="el-icon-warning" style="color:rgb(174, 174, 178);font-size: 12px;">
                </i></el-tooltip>
            </div>
            <el-progress :text-inside="true" :stroke-width="18" :percentage="setRate(total, 'repel')" color="rgb(0, 122, 255)"
              :format="setText(total, 'repel')"></el-progress>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="1">
        <el-divider id='el-divider' direction="vertical"></el-divider>
      </el-col>
      <el-col :span="7">
        <el-col :span="5">
          <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 24 24" width="76" height="76" style="fill: rgb(255, 58, 48);">
            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"></path>
          </svg>
        </el-col>
        <el-col :span="16" class='card_right_info'>
          <div>今日摘鸟 <el-tooltip content="今日设备管理中，网上摘鸟数量"><i class="el-icon-warning" style="color:rgb(174, 174, 178);font-size: 12px;">
              </i></el-tooltip>
          </div>
          <el-progress :text-inside="true" :stroke-width="18" :percentage="setRate(total, 'pick')" color="rgb(255, 58, 48)"
            :format="setText(total, 'pick')"></el-progress>
        </el-col>
      </el-col>
      <el-col :span="1">
        <el-divider id='el-divider' direction="vertical"></el-divider>
      </el-col>
      <el-col :span="7">
        <el-col :span="5">
          <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 24 24" width="76" height="76" style="fill: rgb(255, 149, 2);">
            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"></path>
          </svg>
        </el-col>
        <el-col :span="16" class='card_right_info'>
          <div>今日捕获 <el-tooltip content="今日设备管理中，捕获鸟类数量"><i class="el-icon-warning" style="color:rgb(174, 174, 178);font-size: 12px;">
              </i></el-tooltip>
          </div>
          <el-progress :text-inside="true" :stroke-width="18" color="rgb(255, 149, 2)" :percentage="setRate(total, 'kill')"
            :format="setText(total, 'kill')"></el-progress>
        </el-col>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="7">
        <el-col :span="5">
          <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 24 24" width="76" height="76" style="fill: rgb(0, 122, 255);">
            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"></path>
          </svg>
        </el-col>
        <el-col :span="16" class='card_right_info'>
          <div>累计驱赶 <el-tooltip content="累计驱鸟工作单驱鸟数量"><i class="el-icon-warning" style="color:rgb(174, 174, 178);font-size: 12px;">
              </i></el-tooltip>
          </div>
          <el-progress :text-inside="true" :stroke-width="18" :percentage="setRate(total, 'd30repel')" color="rgb(0, 122, 255)"
            :format="setText(total, 'd30repel')"></el-progress>
        </el-col>
      </el-col>
      <el-col :span="1">
        <el-divider id='el-divider' direction="vertical"></el-divider>
      </el-col>
      <el-col :span="7">
        <el-col :span="5">
          <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 24 24" width="76" height="76" style="fill: rgb(255, 58, 48);">
            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"></path>
          </svg>
        </el-col>
        <el-col :span="16" class='card_right_info'>
          <div>累计驱赶 <el-tooltip content="累计设备管理中，网上摘鸟数量"><i class="el-icon-warning" style="color:rgb(174, 174, 178);font-size: 12px;">
              </i></el-tooltip>
          </div>
          <el-progress :text-inside="true" :stroke-width="18" :percentage="setRate(total, 'd30pick')" color="rgb(255, 58, 48)"
            :format="setText(total, 'd30pick')"></el-progress>
        </el-col>
      </el-col>
      <el-col :span="1">
        <el-divider id='el-divider' direction="vertical"></el-divider>
      </el-col>
      <el-col :span="7">
        <el-col :span="5">
          <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 24 24" width="76" height="76" style="fill: rgb(255, 149, 2);">
            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"></path>
          </svg>
        </el-col>
        <el-col :span="16" class='card_right_info'>
          <div>累计捕获 <el-tooltip content="累计设备管理中，捕获鸟类数量"><i class="el-icon-warning" style="color:rgb(174, 174, 178);font-size: 12px;">
              </i></el-tooltip>
          </div>
          <el-progress :text-inside="true" :stroke-width="18" color="rgb(255, 149, 2)" :percentage="setRate(total, 'd30kill')"
            :format="setText(total, 'd30kill')"></el-progress>
        </el-col>
      </el-col>
    </el-row>
    <div style="height: 30px;"></div>
    <el-row>
      <el-card>
        <el-row>
          <el-col :span="4" :offset="16">
            <el-radio-group v-model="aMapSearch.stage" style="margin-bottom: 30px;" size="mini" @change="drawAmap">
              <el-radio-button label="day">日</el-radio-button>
              <el-radio-button label="month">月</el-radio-button>
              <el-radio-button label="year">年</el-radio-button>
            </el-radio-group>
          </el-col>
          <el-col :span="4">
            <el-select clearable placeholder="种类" size="small" v-model="aMapSearch.birdNo" @change="drawAmap"
              filterable>
              <el-option v-for="item in Config.birdOptions" :key='item.birdNo' :label="item.name" :value="item.birdNo"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <div id='container' style="height: 500px;" v-loading="amapLoading"></div>
        </el-row>
      </el-card>
      <div style="height: 30px;"></div>
      <el-card>
        <el-row>
          <el-col :span="4" :offset="16">
            <el-radio-group v-model="rateSearch.stage" style="margin-bottom: 30px;" size="mini" @change="drawRateCahrt">
              <el-radio-button label="week">周</el-radio-button>
              <el-radio-button label="month">月</el-radio-button>
            </el-radio-group>
          </el-col>
          <el-col :span="4">
            <el-select clearable placeholder="种类" size="small" v-model="rateSearch.birdNo" @change="drawRateCahrt"
              filterable>
              <el-option v-for="item in Config.birdOptions" :key='item.birdNo' :label="item.name" :value="item.birdNo"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <div id="chartColumn2" style="width: 100%; height: 500px;" v-loading="rateLoading">
        </div>
      </el-card>
    </el-row>
    <div style="height: 30px;"></div>
    <el-row>
      <el-card>
        <el-row>
          <el-col :span="4" :offset="16">
            <el-radio-group v-model="barSearch.stage" style="margin-bottom: 30px;" size="mini" @change="drawBarCahrt">
              <el-radio-button label="day">日</el-radio-button>
              <el-radio-button label="month">月</el-radio-button>
              <el-radio-button label="year">年</el-radio-button>
            </el-radio-group>
          </el-col>
          <el-col :span="4">
            <el-select clearable placeholder="种类" size="small" v-model="barSearch.birdNo" @change="drawBarCahrt"
              filterable>
              <el-option v-for="item in Config.birdOptions" :key='item.birdNo' :label="item.name" :value="item.birdNo"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <div id="chartColumn1" style="width: 100%; height: 500px;" v-loading="barLoading">
        </div>
      </el-card>
    </el-row>
    <div style="height: 30px;"></div>
    <el-card>
      <el-row>
        <el-col :span="4" :offset="16">
          <el-radio-group v-model="lineSearch.stage" style="margin-bottom: 30px;" size="mini" @change="drawLineCahrt">
            <el-radio-button label="day">日</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
            <el-radio-button label="year">年</el-radio-button>
          </el-radio-group>
        </el-col>
        <el-col :span="4">
          <el-select clearable placeholder="种类" size="small" v-model="lineSearch.birdNo" @change="drawLineCahrt"
            filterable>
            <el-option v-for="item in Config.birdOptions" :key='item.birdNo' :label="item.name" :value="item.birdNo"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <div id="chartColumn" style="width: 100%; height: 500px;" v-loading="lineLoading"></div>
    </el-card>
  </div>

</template>

<script>
  import * as echarts from 'echarts'
  const TMap = window.TMap;
  // const Loca = window.Loca;

  export default {
    data() {
      return {
        lineLoading: false,
        barLoading: false,
        rateLoading: false,
        amapLoading: false,
        echartLine: null,
        echartBar: null,
        echartRate:null,
        map: null,
        maxV:1,
        lineSearch: {
          stage: "day",
          birdNo: "",
        },
        lineData: {},
        barSearch: {
          stage: "day",
          birdNo: "",
        },
        aMapSearch: {
          stage: "day",
          birdNo: "",
        },
        rateSearch: {
          stage: "week",
          birdNo: "",
        },
        total: {
          d30DeviceBirdTotal: 0,
          d30PickBirdTotal: 0,
          d30RepelBirdTotal: 0,
          deviceBirdTotal: 0,
          repelBirdTotal: 0,
          pickBirdTotal: 0
        },
        chartColumn: null,
      }
    },
    created() {
      this.getTotal();
      // this.lineSearchData();
    },
    mounted() {
      this.drawLineCahrt();
      this.drawBarCahrt();
      this.drawRateCahrt();
      this.drawAmap();
    },
    methods: {
      async drawAmap() {
        this.amapLoading = true;
        var {
          data: res
        } = await this.$http.post("/bird/analyze/amap", this.aMapSearch);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.amapLoading = false;
        var result = res.result;
        var heatmapData = result.data;
        var center = new TMap.LatLng(result.latitude, result.longitude);
        if (this.map != null) this.map.destroy();
        this.map = new TMap.Map("container", {
          zoom: result.zoom,
          pitch: result.pitch,
          center: center,
          rotation: result.rotation,
          maxZoom: 16,
          minZoom: result.zoom - 2,
          baseMap: {
            type: 'satellite',
            features: ['base', 'building2d', 'building3d']
          },
          padding:30,
          ease:{
            duration:1000
          }
        });
        var list = [];
        var i = -1;
        var length = heatmapData.length;
        while (++i < length) {
          var item = heatmapData[i];
          if (item.longitude != null && item.latitude != null){
            if(this.maxV < item.count) this.maxV = item.count;
            list.push({
              lng: parseFloat(item.longitude),
              lat: parseFloat(item.latitude),
              count: item.count
            })
          }
        }
        new TMap.visualization.Heat({
            max: this.maxV, // 热力最强阈值
            min: 0, // 热力最弱阈值
            height: 0, // 峰值高度
            // gradientColor: { // 渐变颜色
            //   0.6: "#673198",
            //   0.8: "#e53390",
            //   0.9: "#ffc95a",
            // },
            radius: 30 // 最大辐射半径
          })
          .addTo(this.map)
          .setData(list); //设置数据
      },
      async drawBarCahrt() {
        this.barLoading = true;
        var {
          data: res
        } = await this.$http.post("/bird/analyze/bar", this.barSearch);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        var dom = document.getElementById("chartColumn1");
        if (echarts.getInstanceByDom(dom))
          this.echartBar.dispose();
        this.echartBar = echarts.init(dom);
        this.barLoading = false;
        this.echartBar.setOption(JSON.parse(res.result), true);
      },
      async drawRateCahrt() {
        this.rateLoading = true;
        var {
          data: res
        } = await this.$http.post("/bird/analyze/rate", this.rateSearch);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        var dom = document.getElementById("chartColumn2");
        if (echarts.getInstanceByDom(dom))
          this.echartRate.dispose();
        this.echartRate = echarts.init(dom);
        this.rateLoading = false;
        this.echartRate.setOption(JSON.parse(res.result), true);
      },
      async drawLineCahrt() {
        this.lineLoading = true;
        var {
          data: res
        } = await this.$http.post("/bird/analyze/line", this.lineSearch);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        var dom = document.getElementById("chartColumn");
        if (echarts.getInstanceByDom(dom))
          this.echartLine.dispose();
        this.echartLine = echarts.init(dom);
        this.lineLoading = false;
        this.echartLine.setOption(JSON.parse(res.result), true);
      },
      async lineSearchData() {
        var {
          data: res
        } = await this.$http.post("/bird/analyze/line", this.lineSearch);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.lineData = JSON.parse(res.result);
      },
      calculation(all, sign) {
        var val = parseInt(sign / all * 100);

        return isNaN(val) ? 0 : val;
      },
      setRate(bean, flag) {
        var all = bean.deviceBirdTotal + bean.repelBirdTotal + bean.pickBirdTotal;
        var d30All = bean.d30DeviceBirdTotal + bean.d30RepelBirdTotal + bean.d30PickBirdTotal;
        switch (flag) {
          case "repel":
            return this.calculation(all, bean.repelBirdTotal);
          case "pick":
            return this.calculation(all, bean.pickBirdTotal);
          case "kill":
            return this.calculation(all, bean.deviceBirdTotal);
          case "d30repel":
            return this.calculation(d30All, bean.d30RepelBirdTotal);
          case "d30pick":
            return this.calculation(d30All, bean.d30PickBirdTotal);
          case "d30kill":
            return this.calculation(d30All, bean.d30DeviceBirdTotal);
          default:
            return 0;
        }
      },
      async getTotal() {
        var {
          data: res
        } = await this.$http.get("/bird/analyze/all", );
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.total = res.result;
      },
      setText(percentage, flag) {
        return () => {
          switch (flag) {
            case "repel":
              return `${percentage.repelBirdTotal} 只`;
            case "pick":
              return `${percentage.pickBirdTotal} 只`;
            case "kill":
              return `${percentage.deviceBirdTotal} 只`;
            case "d30repel":
              return `${percentage.d30RepelBirdTotal} 只`;
            case "d30pick":
              return `${percentage.d30PickBirdTotal} 只`;
            case "d30kill":
              return `${percentage.d30DeviceBirdTotal} 只`;
            default:
              return "0 只";
          }
        }
      },

    }
  }
</script>

<style>
  .card_right_info {
    padding-top: 15px;
  }

  .el-progress {
    padding: 10px 0px;
  }

  .el-progress-bar .el-progress-bar__outer {
    background-color: #b6bfd4;
  }

  #el-divider {
    margin: 20px 8px;
    height: 2em;
    width: 1px;
  }

  .rate {
    margin: 10px;
    font-size: 40px;
  }
</style>
