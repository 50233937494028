<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>日常任务</el-breadcrumb-item>
      <el-breadcrumb-item>驱鸟工作单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :model="searchInfo" ref='repelSearchRef'>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="time">
              <el-tooltip content="搜索的时间段" placement="top" :enterable="false">
                <el-date-picker v-model="searchInfo.time" type="daterange" align="right" unlink-panels
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
                </el-date-picker>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="inspectionUser">
              <el-tooltip content="巡视人" placement="top" :enterable="false">
                <el-select clearable placeholder="巡视人" v-model="searchInfo.inspectionUser" filterable style="width: 100%;" @clear="search" @change="search">
                  <el-option v-for="item in Config.inspectorOptions" :key='item.staffNo' :label="item.name" :value="item.staffNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="type">
              <el-tooltip content="鸟类种类" placement="top" :enterable="false">
                <el-select clearable placeholder="鸟类种类" v-model="searchInfo.type" filterable style="width: 100%;" @clear="search" @change="search">
                  <el-option v-for="item in Config.birdOptions" :key='item.birdNo' :label="item.name" :value="item.birdNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="region">
              <el-tooltip content="出现区域" placement="top" :enterable="false">
                <el-select clearable placeholder="区域" v-model="searchInfo.region" filterable style="width: 100%;" @clear="search" @change="search">
                  <el-option v-for="item in Config.regionOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="influence">
              <el-tooltip content="威胁程度" placement="top" :enterable="false">
                <el-select clearable placeholder="威胁程度" v-model="searchInfo.influence" filterable @clear="search" @change="search">
                  <el-option v-for="item in Config.threatenOptions" :key='item.optionNo' :label="item.optionName" :value="parseInt(item.optionNo)"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item prop="means">
              <el-tooltip content="采取措施" placement="top" :enterable="false">
                <el-select clearable placeholder="采取措施" v-model="searchInfo.means" filterable @clear="search" @change="search">
                  <el-option v-for="item in Config.meansOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="effect">
              <el-tooltip content="效果" placement="top" :enterable="false">
                <el-select clearable placeholder="效果" v-model="searchInfo.effect" filterable @clear="search" @change="search">
                  <el-option v-for="item in Config.effectOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" plain @click='search'>搜索</el-button>
            <el-button type="info" plain @click='resetSearchInfo'>重置</el-button>
            <el-button type="success" v-if="$display('/repelBirds/add')" @click="createRepelBirds">新增</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="pageInfo.listResult" border stripe v-loading='loading'>
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column label="日期" :width="formLabelWidth" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ scope.row.driveDate | formatTimer }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="driveTime" label="发现时间" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ scope.row.driveTime }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="inspectionUser" label="巡视人" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ Config.inspectorDisplay[scope.row.inspectionUser] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="region" label="区域" align="center">
          <template slot-scope='scope'>
            <el-tag>{{Config.regionDisplay['dp' + scope.row.region]}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="birdNo" label="鸟类种类" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ Config.birdDisplay[scope.row.birdNo] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="threaten" label="威胁程度" align="center">
          <template slot-scope='scope'>
            <el-tag>{{Config.threatenDisplay['dp' + scope.row.threaten]}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="measures" label="采取措施" align="center">
          <template slot-scope='scope'>
            <el-tag>{{Config.meansDisplay['dp' + scope.row.measures]}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="effect" label="效果" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ Config.effectDisplay['dp' + scope.row.effect]}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="formLabelWidth" align="center">
          <template slot-scope='scope'>
            <el-tooltip content="查看详情" placement="top" :enterable="false">
              <el-button type="success" icon="el-icon-notebook-2" size="mini" @click='openDetailDialog(scope.row)'></el-button>
            </el-tooltip>
            <el-tooltip content="编辑" placement="top" :enterable="false">
              <el-button type="primary" v-if="$display('/repelBirds/edit')" icon="el-icon-edit" size="mini" @click='editRepelBirdsDetail(scope.row)'></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[1, 5, 10, 20, 30, 40, 100]"
        :page-size="searchInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :current-page.sync='defaultPage' :total="pageInfo.total">
      </el-pagination>
    </el-card>

    <el-dialog title="新增驱鸟事件" :visible.sync="repelBirdVisible" @close="closeRepelBird">
      <el-form :model="repelBirdesInfo" ref='repelBirdsRef' label-position="right">
        <el-row>
          <el-col :span="24">
            <el-form-item label="天气" :label-width="formLabelWidth">
              <el-radio-group v-model="repelBirdesInfo.weather" size='mini'>
                <el-radio-button v-for='item in Config.weatherOptions' :key='item.optionNo' :value='item.optionName' :label="item.optionNo">{{ item.optionName }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="巡视日期" :label-width="formLabelWidth">
              <el-tooltip content="选择巡视日期" placement="top" :enterable="false">
                <el-date-picker v-model="repelBirdesInfo.driveDate" align="right" type="date" placeholder="选择日期"
                  value-format="yyyy-MM-dd" style="width: 100%;">
                </el-date-picker>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="巡视人" :label-width="formLabelWidth">
              <el-tooltip content="巡视人" placement="top" :enterable="false">
                <el-select clearable placeholder="巡视人" v-model="repelBirdesInfo.inspectionUser" filterable>
                  <el-option v-for="item in Config.inspectorOptions" :key='item.staffNo' :label="item.name" :value="item.staffNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="巡逻时间" :label-width="formLabelWidth">
              <el-tooltip content="巡逻时间" placement="top" :enterable="false">
                <el-time-picker v-model="repelBirdesInfo.driveTime" :picker-options="{
                      selectableRange: '00:00:00 - 23:59:59'
                    }"
                  placeholder="巡逻时间" value-format='HH:mm' :default-value="repelBirdesInfo.driveTime" clearable style="width: 100%;">
                </el-time-picker>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="鸟类活动" :label-width="formLabelWidth">
              <el-radio-group v-model="repelBirdesInfo.existsBird" size='mini'>
                <el-radio-button :label="true">有</el-radio-button>
                <el-radio-button :label="false">无</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-show="repelBirdesInfo.existsBird">
          <el-col :span="11">
            <el-form-item label="种类" :label-width="formLabelWidth">
              <el-tooltip content="鸟类种类" placement="top" :enterable="false">
                <el-select clearable placeholder="种类" v-model="repelBirdesInfo.birdNo" filterable>
                  <el-option v-for="item in Config.birdOptions" :key='item.birdNo' :label="item.name" :value="item.birdNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="数量" :label-width="formLabelWidth">
              <el-input v-model="repelBirdesInfo.count" placeholder="发现数量"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-show="repelBirdesInfo.existsBird">
          <el-col :span="11">
            <el-form-item label="威胁程度" :label-width="formLabelWidth">
              <el-tooltip content="威胁程度" placement="top" :enterable="false">
                <el-select clearable placeholder="威胁程度" v-model="repelBirdesInfo.threaten" filterable>
                  <el-option v-for="item in Config.threatenOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="活动高度" prop="hight" :label-width="formLabelWidth">
              <el-tooltip content="活动高度" placement="top" :enterable="false">
                <el-select clearable placeholder="活动高度" v-model="repelBirdesInfo.hight" filterable>
                  <el-option v-for="item in Config.hightOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-show="repelBirdesInfo.existsBird">
          <el-col :span="11">
            <el-form-item label="飞行方向" :label-width="formLabelWidth">
              <el-tooltip content="飞行方向" placement="top" :enterable="false">
                <el-select clearable placeholder="飞行方向" v-model="repelBirdesInfo.flightDirection" filterable>
                  <el-option v-for="item in Config.flightDirectionOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="起落方向" :label-width="formLabelWidth">
              <el-tooltip content="起落方向" placement="top" :enterable="false">
                <el-select clearable placeholder="起落方向" v-model="repelBirdesInfo.landingDirection" filterable>
                  <el-option v-for="item in Config.landingDirectionOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-show="repelBirdesInfo.existsBird">
          <el-col :span="11">
            <el-form-item label="活动区域" :label-width="formLabelWidth">
              <el-tooltip content="活动区域" placement="top" :enterable="false">
                <el-select clearable placeholder="活动区域" v-model="repelBirdesInfo.region" filterable>
                  <el-option v-for="item in Config.regionOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="鸟类行为" prop="hight" :label-width="formLabelWidth">
              <el-tooltip content="鸟类行为" placement="top" :enterable="false">
                <el-select clearable placeholder="鸟类行为" v-model="repelBirdesInfo.behavior" filterable>
                  <el-option v-for="item in Config.behaviorOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-show="repelBirdesInfo.existsBird">
          <el-col :span="11">
            <el-form-item label="进场原因" :label-width="formLabelWidth">
              <el-tooltip content="进场原因" placement="top" :enterable="false">
                <el-select clearable placeholder="进场原因" v-model="repelBirdesInfo.reason" filterable>
                  <el-option v-for="item in Config.reasonOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="采取措施" prop="hight" :label-width="formLabelWidth">
              <el-tooltip content="采取措施" placement="top" :enterable="false">
                <el-select clearable placeholder="采取措施" v-model="repelBirdesInfo.measures" filterable>
                  <el-option v-for="item in Config.meansOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-show="repelBirdesInfo.existsBird">
          <el-col :span="11">
            <el-form-item label="效果" :label-width="formLabelWidth" prop="effect">
              <el-tooltip content="效果" placement="top" :enterable="false">
                <el-select clearable placeholder="效果" v-model="repelBirdesInfo.effect" filterable>
                  <el-option v-for="item in Config.effectOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="repelBirdVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveRepelBirds">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 查看详情dialog -->
    <el-dialog :title="operatorTitle" :visible.sync="repelBirdsDetailVisible" @close="colseDetailDialog">
      <el-form :model="repelBirdesInfo" ref='repelBirdsDetailRef' label-position="right">
        <el-row>
          <el-col :span="24">
            <el-form-item label="天气" :label-width="formLabelWidth" prop="weather">
              <el-radio-group v-model="repelBirdesInfo.weather" size='mini' disabled>
                <el-radio-button v-for='item in Config.weatherOptions' :key='item.optionNo' :value='item.optionName' :label="item.optionNo">{{ item.optionName }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="巡视日期" :label-width="formLabelWidth" prop="driveDate">
              <el-tooltip content="选择巡视日期" placement="top" :enterable="false">
                <el-date-picker v-model="repelBirdesInfo.driveDate" align="right" type="date" placeholder="选择日期"
                  value-format="YYYY-MM-DD HH:mm:ss" disabled style="width: 100%;">
                </el-date-picker>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="巡视人" :label-width="formLabelWidth" prop="inspectionUser">
              <el-tooltip content="巡视人" placement="top" :enterable="false">
                <el-select clearable placeholder="巡视人" v-model="repelBirdesInfo.inspectionUser" filterable disabled>
                  <el-option v-for="item in Config.inspectorOptions" :key='item.staffNo' :label="item.name" :value="item.staffNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="巡逻时间" :label-width="formLabelWidth" prop="driveTime">
              <el-time-picker v-model="repelBirdesInfo.driveTime" :picker-options="{
                    selectableRange: '00:00:00 - 23:59:59'
                  }"
                placeholder="巡逻时间" value-format='HH:mm' :default-value="repelBirdesInfo.driveTime" clearable disabled style="width: 100%;">
              </el-time-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="鸟类活动" :label-width="formLabelWidth" prop="existsBird">
              <el-radio-group v-model="repelBirdesInfo.existsBird" size='mini' :disabled='editStatus'>
                <el-radio-button :label="true">有</el-radio-button>
                <el-radio-button :label="false">无</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20" v-show="repelBirdesInfo.existsBird">
          <el-col :span="11">
            <el-form-item label="种类" :label-width="formLabelWidth" prop="birdNo">
              <el-tooltip content="鸟类种类" placement="top" :enterable="false">
                <el-select clearable placeholder="种类" v-model="repelBirdesInfo.birdNo" filterable :disabled='editStatus'>
                  <el-option v-for="item in Config.birdOptions" :key='item.birdNo' :label="item.name" :value="item.birdNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="数量" :label-width="formLabelWidth" prop="count">
              <el-input v-model="repelBirdesInfo.count" placeholder="发现数量" :disabled='editStatus'></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-show="repelBirdesInfo.existsBird">
          <el-col :span="11">
            <el-form-item label="威胁程度" :label-width="formLabelWidth" prop="threaten">
              <el-tooltip content="威胁程度" placement="top" :enterable="false">
                <el-select clearable placeholder="威胁程度" v-model="repelBirdesInfo.threaten" filterable :disabled='editStatus'>
                  <el-option v-for="item in Config.threatenOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="活动高度" prop="hight" :label-width="formLabelWidth">
              <el-tooltip content="活动高度" placement="top" :enterable="false">
                <el-select clearable placeholder="活动高度" v-model="repelBirdesInfo.hight" filterable :disabled='editStatus'>
                  <el-option v-for="item in Config.hightOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-show="repelBirdesInfo.existsBird">
          <el-col :span="11">
            <el-form-item label="飞行方向" :label-width="formLabelWidth" prop="flightDirection">
              <el-tooltip content="飞行方向" placement="top" :enterable="false">
                <el-select clearable placeholder="飞行方向" v-model="repelBirdesInfo.flightDirection" filterable :disabled='editStatus'>
                  <el-option v-for="item in Config.flightDirectionOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="起落方向" :label-width="formLabelWidth" prop="landingDirection">
              <el-tooltip content="起落方向" placement="top" :enterable="false">
                <el-select clearable placeholder="起落方向" v-model="repelBirdesInfo.landingDirection" filterable :disabled='editStatus'>
                  <el-option v-for="item in Config.landingDirectionOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-show="repelBirdesInfo.existsBird">
          <el-col :span="11">
            <el-form-item label="活动区域" :label-width="formLabelWidth" prop="region">
              <el-tooltip content="活动区域" placement="top" :enterable="false">
                <el-select clearable placeholder="活动区域" v-model="repelBirdesInfo.region" filterable :disabled='editStatus'>
                  <el-option v-for="item in Config.regionOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="鸟类行为" :label-width="formLabelWidth" prop="behavior">
              <el-tooltip content="鸟类行为" placement="top" :enterable="false">
                <el-select clearable placeholder="鸟类行为" v-model="repelBirdesInfo.behavior" filterable :disabled='editStatus'>
                  <el-option v-for="item in Config.behaviorOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-show="repelBirdesInfo.existsBird">
          <el-col :span="11">
            <el-form-item label="进场原因" :label-width="formLabelWidth" prop="reason">
              <el-tooltip content="进场原因" placement="top" :enterable="false">
                <el-select clearable placeholder="进场原因" v-model="repelBirdesInfo.reason" filterable :disabled='editStatus'>
                  <el-option v-for="item in Config.reasonOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="采取措施" :label-width="formLabelWidth" prop="measures">
              <el-tooltip content="采取措施" placement="top" :enterable="false">
                <el-select clearable placeholder="采取措施" v-model="repelBirdesInfo.measures" filterable :disabled='editStatus'>
                  <el-option v-for="item in Config.meansOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-show="repelBirdesInfo.existsBird">
          <el-col :span="11">
            <el-form-item label="效果" :label-width="formLabelWidth" prop="effect">
              <el-tooltip content="效果" placement="top" :enterable="false">
                <el-select clearable placeholder="效果" v-model="repelBirdesInfo.effect" filterable :disabled='editStatus'>
                  <el-option v-for="item in Config.effectOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="repelBirdsDetailVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRepelBirdsInfo" v-show="editSubmit">保 存</el-button>
        <el-button type="primary" @click="editRepelBirds" v-show="!editSubmit">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created() {
      this.initTime();
      this.getList();
    },
    filters: {
      formatTimer: function(value) {
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        return y + "-" + MM + "-" + d;
      }
    },
    mounted() {
      let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
      this.timer1 = setInterval(() => {
        _this.timer = new Date(); // 修改数据date
      }, 1000)
    },
    beforeDestroy() {
      if (this.timer1) {
        clearInterval(this.timer1); // 在Vue实例销毁前，清除我们的定时器
      }
    },
    methods: {
      search(){
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.getList();
      },
      initTime(){
        var date = new Date();
        this.searchInfo.time[0] = new Date(date.setDate(date.getDate()-7));
        this.searchInfo.time[1] = new Date();
      },
      async editRepelBirdsInfo() {
        const {data:res} = await this.$http.post("/repelBirds/edit", this.repelBirdesInfo);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success("修改成功");
        this.repelBirdsDetailVisible = false;
        this.getList();
      },
      createRepelBirds() {
        this.repelBirdVisible = true
        this.resetAddInfo();
      },
      colseDetailDialog() {
        this.$refs.repelBirdsDetailRef.resetFields();
        this.resetAddInfo();
      },
      editRepelBirds() {
        this.editSubmit = true;
        this.editStatus = false;
        this.operatorTitle = '驱鸟事件信息修改';
      },
      editRepelBirdsDetail(rowData) {
        this.repelBirdesInfo = JSON.parse(JSON.stringify(rowData));
        this.repelBirdsDetailVisible = true;
        this.editRepelBirds();
      },
      openDetailDialog(rowData) {
        this.operatorTitle = '驱鸟事件详情';
        this.editStatus = true;
        this.editSubmit = false;
        this.repelBirdsDetailVisible = true;
        this.repelBirdesInfo = JSON.parse(JSON.stringify(rowData));
      },
      closeRepelBird() {
        this.resetAddInfo();
        this.$refs.repelBirdsRef.resetFields();
      },
      async saveRepelBirds() {
        console.log(this.repelBirdesInfo);
        this.repelBirdesInfo.driveDate = this.moment(this.repelBirdesInfo.driveDate).format("YYYY-MM-DD HH:mm:ss")
        var {
          data: res
        } = await this.$http.post("/repelBirds/add", this.repelBirdesInfo);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success("添加成功");
        this.repelBirdVisible = false;
        this.getList();
      },
      async getList() {
        this.loading = true;
        if(this.searchInfo.time.length == 2){
          this.searchInfo.time[0] = this.moment(this.searchInfo.time[0]).format('YYYY-MM-DD HH:mm:ss');
          this.searchInfo.time[1] = this.moment(this.searchInfo.time[1]).format('YYYY-MM-DD HH:mm:ss');
        }
        var {
          data: res
        } = await this.$http.post("/repelBirds/list", this.searchInfo);
        this.loading = false;
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.pageInfo = res.result;
      },
      resetSearchInfo() {
        this.$refs.repelSearchRef.resetFields();
        this.getList();
      },
      getCurrentTime() {
        var date = new Date();
        return date.getHours() + ":" + date.getMinutes();
      },
      handleSizeChange(val) {
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.searchInfo.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.searchInfo.page = val;
        this.getList();
      },
      resetAddInfo() {

        this.repelBirdesInfo = {
          driveDate: new Date(),
          weather: 1,
          driveTime: this.getCurrentTime(),
          existsBird: true,
          inspectionUser: '',
          region: '',
          birdNo: '',
          threaten: '',
          measures: '',
          effect: '',
          count: '',
          hight: '',
          flightDirection: '',
          landingDirection: '',
          reason: '',
          behavior: ''
        };
      }
    },
    data() {
      return {
        defaultPage:1,
        loading:false,
        editSubmit: false,
        editStatus: true,
        operatorTitle: '驱鸟事件详情',
        repelBirdList: [],
        formLabelWidth: '120px',
        repelBirdsDetailVisible: false,
        repelBirdesInfo: {
          driveDate: new Date(),
          driveTime: this.getCurrentTime(),
          existsBird: true,
          weather: 1,
          inspectionUser: '',
          region: '',
          birdNo: '',
          threaten: '',
          measures: '',
          effect: '',
          count: '',
          hight: '',
          flightDirection: '',
          landingDirection: '',
          reason: '',
          behavior: ''
        },
        repelBirdVisible: false,
        searchInfo: {
          time: [],
          inspectionUser: '',
          type: '',
          region: '',
          influence: '',
          means: '',
          effect: '',
          page:1,
          pageSize:10
        },
        pageInfo: {
          firstPage: false,
          lastPage: false,
          listResult: [],
          navigatepageNums: [],
          pageCount: 0,
          total: 0
        },
      }
    }
  }
</script>

<style>
</style>
