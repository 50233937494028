<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限配置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :model="searchInfo">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item prop="name">
              <el-input placeholder="请输入角色名称" v-model="searchInfo.name" @keyup.enter.native='search' clearable @clear="search">
                <el-button slot="append" icon="el-icon-search" @click='search'></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="success" v-if="$display('/role/add')" @click="openAddDialog">新增</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="pageInfo.listResult" border v-loading='loading'>
        <el-table-column type="index" align="center" label="#"></el-table-column>
        <el-table-column prop="name" align="center" label="角色名称"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope = 'scope'>
            <el-tooltip content="编辑" placement="top" :enterable="false">
              <el-button type="primary" v-if="$display('/role/edit')" icon="el-icon-edit" size="mini" @click='openEditDataInfo(scope.row)'></el-button>
            </el-tooltip>
            <el-popconfirm style='margin-left: 10px; margin-right: 10px;' confirmButtonText='确定' cancelButtonText='取消'
              icon="el-icon-info" iconColor="red" title="确定删除这条记录吗？" @onConfirm="delDataInfo(scope.row)">
              <el-button slot="reference" v-if="$display('/role/del')" type="danger" icon="el-icon-delete" size="mini"></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[1, 5, 10, 20, 30, 40, 100]"
        :page-size="searchInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :current-page.sync='defaultPage' :total="pageInfo.total">
      </el-pagination>
    </el-card>
    <el-dialog :title="dialogTitle" :visible.sync='dialogVisible' width='60%' @close="closeDialog">

      <el-form :model="dataInfo" ref='formDataInfoRef' :rules="rules">
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="名称" prop="name" :label-width="formLabelWidth">
              <el-input v-model="dataInfo.name" placeholder="请输入名称,此名称唯一"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item label="权限分配" prop="permissionList" :label-width="formLabelWidth">
              <el-transfer v-model="dataInfo.permissionList" :titles='titles' :data="permissionData" style="width: 100%;align-items: center;"></el-transfer>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveDataInfo" v-show='saveButton'>确 定</el-button>
        <el-button type="primary" @click="editDataInfo" v-show='editButton'>保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created() {
      this.getList();
      this.getOptions();
    },
    methods: {
      search(){
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.getList();
      },
      closeDialog(){
        this.$refs.formDataInfoRef.resetFields();
      },
      handleSizeChange(val) {
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.searchInfo.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.searchInfo.page = val;
        this.getList();
      },
      openEditDataInfo(rowData){
        this.dataInfo = JSON.parse(JSON.stringify(rowData));
        this.dialogTitle = '修改分配权限';
        this.saveButton = false;
        this.editButton = true;
        this.dialogVisible = true;
      },
      async delDataInfo(rowData){
        var {
          data: res
        } = await this.$http.delete("/role/del/" + rowData.id);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success('删除成功');
        this.getList();
      },
      async getOptions() {
        var {
          data: res
        } = await this.$http.get("/permission/options/list");
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        res.result.forEach((value, index) => {
          this.permissionData.push({
            label: value.name,
            key: value.no,
          });
        })
      },
      openAddDialog() {
        this.saveButton = true;
        this.editButton = false;
        this.dialogTitle = "新增配置";
        this.dialogVisible = true;
      },
      saveDataInfo() {
        this.$refs.formDataInfoRef.validate(async (valid) => {
          if (!valid)
            return;
          var {
            data: res
          } = await this.$http.post("/role/add", this.dataInfo);
          if (!res.success) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success('添加成功');
          this.getList();
          this.dialogVisible = false;
        });

      },
      editDataInfo() {
        this.$refs.formDataInfoRef.validate(async (valid) => {
          if (!valid)
            return;
          var {
            data: res
          } = await this.$http.post("/role/edit", this.dataInfo);
          if (!res.success) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success('修改成功');
          this.getList();
          this.dialogVisible = false;
        });
      },
      filterMethod(query, item) {

      },
      async getList() {
        this.loading = true;
        var {
          data: res
        } = await this.$http.post("/role/list", this.searchInfo);
         this.loading = false;
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.pageInfo = res.result;
      }
    },
    data() {
      return {
        rules: {
          name: [{
            required: true,
            message: "角色名字不能为空",
            trigger: 'blur'
          }]
        },
        pageInfo: {
          firstPage: false,
          lastPage: false,
          listResult: [],
          navigatepageNums: [],
          pageCount: 0,
          total: 0
        },
        dataInfo: {
          name: '',
          permissionNo: []
        },
        loading:false,
        titles: ['待分配权限', '目标分配权限'],
        defaultPage:1,
        permissionData: [],
        formLabelWidth: '120px',
        saveButton: false,
        editButton: false,
        dialogTitle: '',
        dialogVisible: false,
        dataInfoList: [],
        searchInfo: {
          name: '',
          page: 1,
          pageSize: 10
        }
      }
    }
  }
</script>

<style>
</style>
