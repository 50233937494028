<template>
  <div class='login_container'>
    <div class="login_box">
      <!-- 头像 -->
      <div class='logo'>
        <img src="../assets/logo.png" />
      </div>
      <el-form ref='loginFormRef' label-width="0px" class='login_form' :model="loginUser" :rules='loginRules'>
        <!-- 用户名 -->
        <el-form-item prop='loginName'>
          <el-input prefix-icon="el-icon-user-solid" placeholder="请输入用户名" v-model="loginUser.loginName" clearable></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop='password'>
          <el-input prefix-icon="el-icon-key" placeholder="清输入密码" v-model="loginUser.password" type='password'
            clearable></el-input>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item class="btn">
          <el-button type="primary" @click='login'>登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-row>
        <el-col :span="3" :offset='21' style="position: fixed;bottom: 20px;">
          <a href="https://beian.miit.gov.cn">京ICP备16034688号-1</a>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loginUser: {
          loginName: '',
          password: ''

        },
        loginRules: {
          loginName: [{
              required: true,
              message: '请输入用户名',
              trigger: 'blur'
            },
            {
              min: 6,
              max: 30,
              message: '长度在 6 到 30 个字符',
              trigger: 'blur'
            }
          ],
          password: [{
              required: true,
              message: '请输入密码',
              trigger: 'blur'
            },
            {
              min: 6,
              max: 30,
              message: '长度在 6 到 30 个字符',
              trigger: 'blur'
            }
          ]
        }
      }
    },
    methods: {
      login() {
        this.$refs.loginFormRef.validate(async (valid) => {
          if (!valid)
            return;

          const {
            data: res
          } = await this.$http.post('/staff/login', this.loginUser);
          if ("SUCCESS" != res.code) {
            this.$message.error(res.message);
            return;
          }
          var userInfo = res.result;
          localStorage.setItem('token', userInfo.token);
          localStorage.setItem('userName', userInfo.name);
          localStorage.setItem('airportName', userInfo.airportName);
          this.$message.success("登录成功");
          this.$router.push('/home');
        });
      }
    },
  }
</script>

<style lang="less" scoped>
  .login_box {
    width: 450px;
    height: 300px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);

    .logo {
      width: 130px;
      height: 130px;
      border: 1px solid #eee;
      border-radius: 50%;
      padding: 10px;
      position: absolute;
      left: 50%;
      box-shadow: 0 0 10px #ddd;
      transform: translate(-50%, -50%);
      background-color: #fff;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #eee;
      }
    }
  }

  .btn {
    display: flex;
    justify-content: flex-end;
  }

  .login_form {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .login_container {
    background-image: url('../assets/bg-sky.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #6c757d;
    height: 100%;
  }
</style>
