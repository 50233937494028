<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>日常任务</el-breadcrumb-item>
      <el-breadcrumb-item>鼠药管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :model="searchInfo" ref='searchInfoRef'>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="time">
              <el-tooltip content="搜索的时间段" placement="top" :enterable="false">
                <el-date-picker v-model="searchInfo.time" type="daterange" align="right" unlink-panels range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
                </el-date-picker>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="region">
              <el-select clearable placeholder="区域" v-model="searchInfo.region" filterable style="width: 100%;" @clear="search"
                @change="search">
                <el-option v-for="item in Config.regionOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="inspector">
              <el-select clearable placeholder="巡视人" v-model="searchInfo.inspector" filterable style="width: 100%;"
                @clear="search" @change="search">
                <el-option v-for="item in Config.inspectorOptions" :key='item.staffNo' :label="item.name" :value="item.staffNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" plain @click='search'>搜索</el-button>
            <el-button type="info" plain @click='resetSearchInfo'>重置</el-button>
            <el-button type="success" @click="addTask" v-if="$display('/rodenticide/add')">新增</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="pageInfo.listResult" border v-loading='loading'>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="timeStr" label="时间" align="center">
          <template slot-scope='scope'>
            <el-tag>{{scope.row.timeStr}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="region" label="区域" align="center">
          <template slot-scope='scope'>
            <el-tag>{{Config.regionDisplay['dp' + scope.row.region]}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="boxNo" label="鼠盒编号" align="center">
          <template slot-scope='scope'>
            <el-tag>{{Config.boxDisplay['dp' + scope.row.boxNo]}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="count" label="数量" align="center">
          <template slot-scope='scope'>
            <el-tag>{{scope.row.count}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="inspector" label="操作人" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ Config.inspectorDisplay[scope.row.inspector] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="place" label="是否放置" align="center">
          <template slot-scope='scope'>
            <el-tag v-if="scope.row.place == true" type='success'>放置</el-tag>
            <el-tag v-else type="danger">未放置</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope='scope'>
            <el-tooltip content="查看详情" placement="top" :enterable="false">
              <el-button type="success" icon="el-icon-notebook-2" size="mini" @click='openDetailDialog(scope.row)'></el-button>
            </el-tooltip>
            <el-tooltip content="编辑" placement="top" :enterable="false">
              <el-button type="primary" v-if="$display('/rodenticide/edit')" icon="el-icon-edit" size="mini" @click='editDetailDialog(scope.row)'></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[1, 5, 10, 20, 30, 40, 100]"
        :page-size="searchInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :current-page.sync='defaultPage'
        :total="pageInfo.total">
      </el-pagination>
    </el-card>
    <el-dialog :title="dialogTitle" :visible.sync='dialogVisible' @close="closeDialog">
      <el-form :model="dataInfo" ref='dataInfoRef'>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="time" label="日期" :label-width="labelWidth">
              <el-date-picker v-model="dataInfo.time" value-format="yyyy-MM-dd HH:mm" type="date" placeholder="选择日期时间" style="width: 100%;"
                :disabled="disable1">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="region" label="区域" :label-width="labelWidth">
              <el-select clearable placeholder="活动区域" v-model="dataInfo.region" filterable :disabled="disable2">
                <el-option v-for="item in Config.regionOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="boxNo" label="鼠盒编号" :label-width="labelWidth">
              <el-select clearable placeholder="鼠盒编号" v-model="dataInfo.boxNo" filterable :disabled="disable2">
                <el-option v-for="item in Config.boxOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="count" label="数量" :label-width="labelWidth">
              <el-input v-model="dataInfo.count" placeholder="请输入数量" :disabled="disable2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="inspector" label="巡视人" :label-width="labelWidth">
              <el-select clearable placeholder="巡视人" v-model="dataInfo.inspector" filterable style="width: 100%;"
                :disabled="disable2">
                <el-option v-for="item in Config.inspectorOptions" :key='item.staffNo' :label="item.name" :value="item.staffNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="place" label="是否放置" :label-width="labelWidth">
              <el-radio-group v-model="dataInfo.place" :disabled="disable2">
                <el-radio-button :label="1">放置</el-radio-button>
                <el-radio-button :label="0">未放置</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="22">
            <el-form-item prop="memo" label="备注" :label-width="labelWidth">
              <el-input :disabled="disable2" type="textarea" maxlength="300" v-model="dataInfo.memo" placeholder="请输入备注"
                :autosize="{ minRows: 2, maxRows: 4}" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateStatus" v-show='updateButton'>修 改</el-button>
        <el-button type="primary" @click="editTask" v-show='editButton'>保 存</el-button>
        <el-button type="primary" @click="saveTask" v-show='addButton'>确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created() {
      this.initTime();
      this.getList();
    },
    methods: {
      search(){
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.getList();
      },
      initTime() {
        var date = new Date();
        this.searchInfo.time[0] = new Date(date.setDate(date.getDate() - 7));
        this.searchInfo.time[1] = new Date();
      },
      closeDialog() {
        this.$refs.dataInfoRef.resetFields();
      },
      updateStatus() {
        this.addButton = false;
        this.editButton = true;
        this.updateButton = false;
        this.disable1 = true;
        this.disable2 = false;
        this.dialogTitle = '修改鼠药信息';
      },
      openDetailDialog(rowData) {
        this.addButton = false;
        this.editButton = false;
        this.updateButton = true;
        this.disable1 = true;
        this.disable2 = true;
        this.dialogTitle = '查看鼠药信息';
        this.dataInfo = JSON.parse(JSON.stringify(rowData));
        this.dialogVisible = true;
      },
      editDetailDialog(rowData) {
        this.addButton = false;
        this.editButton = true;
        this.updateButton = false;
        this.disable1 = true;
        this.disable2 = false;
        this.dialogTitle = '修改鼠药信息';
        this.dataInfo = JSON.parse(JSON.stringify(rowData));
        this.dialogVisible = true;
      },
      addTask() {
        this.addButton = true;
        this.editButton = false;
        this.updateButton = false;
        this.disable1 = false;
        this.disable2 = false;
        this.dialogTitle = '新增鼠药信息';
        this.dialogVisible = true;
      },
      async editTask() {
        var {
          data: res
        } = await this.$http.post("/rodenticide/edit", this.dataInfo);
        if (!res.success) {
          this.$message.error(res.messge);
          return;
        }
        this.$message.success("信息修改成功");
        this.getList();
        this.dialogVisible = false;
      },
      async saveTask() {
        if(this.dataInfo.time != null){
          this.dataInfo.time = this.moment(this.dataInfo.time).format("YYYY-MM-DD HH:mm:ss");
        }
        var {
          data: res
        } = await this.$http.post("/rodenticide/add", this.dataInfo);
        if (!res.success) {
          this.$message.error(res.messge);
          return;
        }
        this.$message.success("信息添加成功");
        this.getList();
        this.dialogVisible = false;
      },
      async getList() {
        this.loading = true;
        if(this.searchInfo.time.length == 2){
          this.searchInfo.time[0] = this.moment(this.searchInfo.time[0]).format('YYYY-MM-DD HH:mm:ss');
          this.searchInfo.time[1] = this.moment(this.searchInfo.time[1]).format('YYYY-MM-DD HH:mm:ss');
        }
        var {
          data: res
        } = await this.$http.post("/rodenticide/list", this.searchInfo);
        this.loading = false;
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.pageInfo = res.result;
      },
      resetSearchInfo() {
        this.$refs.searchInfoRef.resetFields();
        this.getList();
      },
      handleSizeChange(val) {
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.searchInfo.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.searchInfo.page = val;
        this.getList();
      },
    },
    data() {
      return {
        defaultPage: 1,
        updateButton: false,
        editButton: false,
        addButton: false,
        loading: false,
        disable1: false,
        disable2: false,
        labelWidth: '120px',
        dialogVisible: false,
        dialogTitle: '',
        dataInfo: {
          time: new Date(),
          region: '',
          boxNo: '',
          count: 0,
          inspector: '',
          place: 1,
          memo: ''
        },
        pageInfo: {
          firstPage: false,
          lastPage: false,
          listResult: [],
          navigatepageNums: [],
          pageCount: 0,
          total: 0
        },
        rodenticideList: [],
        searchInfo: {
          time: [],
          inspector: '',
          region: '',
          page: 1,
          pageSize: 10
        },
        regionList: [{
            value: 1,
            label: "A区"
          },
          {
            value: 2,
            label: "B区"
          },
          {
            value: 3,
            label: "C区"
          },
          {
            value: 4,
            label: "D区"
          },
          {
            value: 5,
            label: "E区"
          },
        ],
      }
    }
  }
</script>

<style>
</style>
