<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>事件管理</el-breadcrumb-item>
      <el-breadcrumb-item>鸟击事件</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :model="searchInfo" ref="birdStrikeSearchFormRef">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="time">
              <el-date-picker type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd" v-model="searchInfo.time" style="width: 100%;">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="eventNo">
              <el-tooltip content="事件编号" placement="top" :enterable="false">
                <el-input v-model="searchInfo.eventNo" placeholder="事件编号" clearable @keyup.enter.native='search' @clear="search"></el-input>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="birdStrike">
              <el-tooltip content="是否为鸟击事件" placement="top" :enterable="false">
                <el-select clearable placeholder="是否为鸟击事件" v-model="searchInfo.birdStrike" filterable @change='search' @clear="search">
                  <el-option v-for="item in birdStrikeOption" :key='item.value' :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="flightNumber">
              <el-tooltip content="航班号" placement="top" :enterable="false">
                <el-input v-model="searchInfo.flightNumber" placeholder="航班号" clearable @keyup.enter.native='search' @clear="search"></el-input>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="model">
              <el-tooltip content="航空器型号" placement="top" :enterable="false">
                <el-input v-model="searchInfo.model" placeholder="航空器型号" clearable @keyup.enter.native='search' @clear="search"></el-input>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item prop="flightPhase">
              <el-tooltip content="飞行阶段" :enterable="false" placement="top">
                <el-select clearable placeholder="飞行阶段" v-model="searchInfo.flightPhase" filterable @change='search' @clear="search">
                  <el-option v-for="item in Config.flightPhaseOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                </el-select>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" plain @click='search'>搜索</el-button>
            <el-button type="info" plain @click='resetSearchInfo'>重置</el-button>
            <el-button type="success" v-if="$display('/birdStrike/add')" @click="createBirdStrikeEvent">新增</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="pageInfo.listResult" border v-loading="loading">
        <el-table-column type="index" label="#">
        </el-table-column>
        <el-table-column align="center" prop="no" label="编号">
        </el-table-column>
        <el-table-column align="center" prop="birdStrike" label="是否为鸟击事件">
          <template slot-scope='scope'>
            <el-tag type="danger" v-if="scope.row.birdStrike">是</el-tag>
            <el-tag type="success" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="flightNumber" label="航班号"></el-table-column>
        <el-table-column align="center" prop="model" label="航空器型号"></el-table-column>
        <el-table-column align="center" prop="flightPhase" label="飞行阶段">
         <template slot-scope='scope'>
            <el-tag>{{ Config.flightPhaseDisplay['dp' + scope.row.flightPhase ] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="height" label="离地高度"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope='scope'>
            <el-tooltip content="查看详情" placement="top" :enterable="false">
              <el-button type="success" icon="el-icon-notebook-2" size="mini" @click='openDetailDialog(scope.row)'></el-button>
            </el-tooltip>
            <el-tooltip content="编辑" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini" v-if="$display('/birdStrike/edit')" @click='editDetailDialog(scope.row)'></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[1, 5, 10, 20, 30, 40, 100]"
        :page-size="searchInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :current-page.sync='defaultPage'
        :total="pageInfo.total">
      </el-pagination>
    </el-card>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" @close="closeDiaLog">

      <el-form :model="baseInfo" ref="BaseInfoRef">
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="no" label="事件编号" :label-width="fromLabWidth">
              <el-input v-model="baseInfo.no" placeholder="请输入事件编号" clearable :disabled="disable1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="eventDate" label="发生日期" :label-width="fromLabWidth">
              <el-tooltip content="选择发生日期" placement="top" :enterable="false">
                <el-date-picker v-model="baseInfo.eventDate" align="right" type="date" placeholder="选择日期" :disabled="disable2"
                  value-format="yyyy-MM-dd HH" style="width: 100%;">
                </el-date-picker>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="enterprises" label="航空运输企业" :label-width="fromLabWidth">
              <el-input v-model="baseInfo.enterprises" placeholder="请输入运输企业" clearable :disabled="disable2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="model" label="机型" :label-width="fromLabWidth">
              <el-input v-model="baseInfo.model" placeholder="请输入机型" clearable :disabled="disable2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="regNumber" label="航空器注册号" :label-width="fromLabWidth">
              <el-input v-model="baseInfo.regNumber" placeholder="请输入航空器注册号" clearable :disabled="disable2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="flightNumber" label="航班号" :label-width="fromLabWidth">
              <el-input v-model="baseInfo.flightNumber" placeholder="请输入航班号" clearable :disabled="disable2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="flightStart" label="起飞机场" :label-width="fromLabWidth">
              <el-input v-model="baseInfo.flightStart" placeholder="请输入起飞机场" clearable :disabled="disable2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="flightEnd" label="着陆机场" :label-width="fromLabWidth">
              <el-input v-model="baseInfo.flightEnd" placeholder="请输入着陆机场" clearable :disabled="disable2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="birdStrike" label="是否为鸟击事件" :label-width="fromLabWidth">
              <el-radio-group v-model="baseInfo.birdStrike" :disabled="disable2">
                <el-radio-button :label="1">是</el-radio-button>
                <el-radio-button :label="0">否</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveBaseInfo" v-show="baseButton" :disabled="preventionDbClick">保存基本信息</el-button>
        <el-button type="primary" @click="editBaseInfo" v-show="updateButton" :disabled="preventionDbClick">保存信息</el-button>
        <el-button type="primary" @click="updateBaseInfo" v-show="otherButton" :disabled="preventionDbClick">修 改</el-button>
      </span>
      <el-tabs v-show="!baseButton" v-model="activeName" type="card" stretch>
        <el-tab-pane label="详细信息" name="detail">
          <el-form :model="baseInfo" ref='detailInfoRef'>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="eventTime" label="撞击时间" :label-width="fromLabWidth">
                  <el-input v-model="baseInfo.eventTime" placeholder="请输入撞击时间" clearable :disabled="disable3"></el-input>
                  <!-- <el-time-picker :disabled="disable3" v-model="baseInfo.eventTime"
                      :picker-options="{
                          start: '08:30',
                          step: '00:15',
                          end: '18:30'
                        }"
                    placeholder="撞击时间" style="width: 100%;">
                  </el-time-picker> -->
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item prop="timeNode" label="撞击时间段" :label-width="fromLabWidth">
                  <el-select clearable placeholder="撞击时间段" v-model="baseInfo.timeNode" filterable :disabled="disable3">
                    <el-option v-for="item in Config.timeNodeOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="selfResponsibility" label="本机场责任" :label-width="fromLabWidth">
                  <el-tooltip content="是否为本机场责任" placement="top" :enterable="false">
                    <el-radio-group v-model="baseInfo.selfResponsibility" :disabled="disable3">
                      <el-radio-button label="1">是</el-radio-button>
                      <el-radio-button label="0">否</el-radio-button>
                    </el-radio-group>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item prop="waring" label="警告" :label-width="fromLabWidth">
                  <el-tooltip content="飞机是否被警告过要注意鸟或其他动物" placement="top" :enterable="false">
                    <el-radio-group v-model="baseInfo.waring" :disabled="disable3">
                      <el-radio-button label="1">是</el-radio-button>
                      <el-radio-button label="0">否</el-radio-button>
                    </el-radio-group>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="airspeed" label="指示空速" :label-width="fromLabWidth">
                  <el-input v-model="baseInfo.airspeed" placeholder="指示空速" clearable :disabled="disable3">
                    <template slot="append">节</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item prop="height" label="离地高度" :label-width="fromLabWidth">
                  <el-input placeholder="请输入内容" v-model="baseInfo.height" class="input-with-select input-select"
                    :disabled="disable3">
                    <el-select v-model="baseInfo.heightUnit" :disabled="disable3" class='input-select' slot="append"
                      placeholder="请选择">
                      <el-option label="米" :value="0"></el-option>
                      <el-option label="英尺" :value="1"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="eventPlace" label="发生地" :label-width="fromLabWidth">
                  <el-select clearable placeholder="发生地" :disabled="disable3" v-model="baseInfo.eventPlace" filterable>
                    <el-option v-for="item in Config.eventPlaceOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item prop="runwayNo" v-if="baseInfo.eventPlace == 1 || baseInfo.eventPlace == 2" label="跑道号"
                  :label-width="fromLabWidth">
                  <el-input v-model="baseInfo.runwayNo" placeholder="跑道号" :disabled="disable3"></el-input>
                </el-form-item>
                <el-form-item prop="" v-else-if="baseInfo.eventPlace == 3" label="航路" :label-width="fromLabWidth">
                  <!-- <el-input v-model="baseInfo.eventPlaceLocation" placeholder="航路" :disabled="disable3"></el-input> -->
                  <el-cascader
                      v-model="baseInfo.eventPlaceLocation"
                      :options="Config.chinaJson"
                      :props='chinaProp'
                      @change="handleChange" style="width: 100%;" :disabled="disable3"></el-cascader>
                </el-form-item>
                <el-form-item v-else></el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="flightPhase" label="飞行阶段" :label-width="fromLabWidth">
                  <el-select clearable placeholder="飞行阶段" :disabled="disable3" v-model="baseInfo.flightPhase"
                    filterable>
                    <el-option v-for="item in Config.flightPhaseOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

        </el-tab-pane>
        <el-tab-pane label="环境及收集" name="collection">
          <el-form :model="baseInfo" ref='collectionRef'>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="weather" label="天空情况" :label-width="fromLabWidth">
                  <el-select clearable placeholder="天空情况" :disabled="disable3" v-model="baseInfo.weather" filterable>
                    <el-option v-for="item in Config.airOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item prop="precipitation" label="降水" :label-width="fromLabWidth">
                  <el-select clearable placeholder="降水" :disabled="disable3" v-model="baseInfo.precipitation"
                    filterable>
                    <el-option v-for="item in Config.precipitationOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop='collection' label="采集情况" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.collection" :disabled="disable3">
                    <el-radio-button label="1">是</el-radio-button>
                    <el-radio-button label="0">否</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item prop='impactSpecies' label="撞击物种" :label-width="fromLabWidth">
                  <el-input v-model="baseInfo.impactSpecies" placeholder="请输入撞击物种" :disabled="disable3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop='residue' label="残留物" :label-width="fromLabWidth">
                  <el-select clearable placeholder="残留物" v-model="baseInfo.residue" filterable :disabled="disable3">
                    <el-option v-for="item in Config.residueOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item prop='shape' label="体型" :label-width="fromLabWidth">
                  <el-select clearable placeholder="体型" v-model="baseInfo.shape" filterable :disabled="disable3">
                    <el-option v-for="item in Config.shapeOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop='number' label="数量" :label-width="fromLabWidth">
                  <el-select clearable placeholder="数量" v-model="baseInfo.number" filterable :disabled="disable3">
                    <el-option v-for="item in Config.countOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item prop='hit' label="是否击中" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.hit" :disabled="disable3">
                    <el-radio-button label="1">击中</el-radio-button>
                    <el-radio-button label="0">看到</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="飞行器损伤" name="aircrraftDamage">

          <el-form :model="baseInfo">
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="radar" label="雷达罩" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.radar" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="11">
                <el-form-item prop="windshield" label="风挡" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.windshield" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="head" label="机头" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.head" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="11">
                <el-form-item prop="engine1" label="发动机1" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.engine1" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="engine2" label="发动机2" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.engine2" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="11">
                <el-form-item prop="engine3" label="发动机3" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.engine3" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="engine4" label="发动机4" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.engine4" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="11">
                <el-form-item prop="propeller" label="螺旋桨" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.propeller" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="wing" label="机翼" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.wing" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="11">
                <el-form-item prop="body" label="机身" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.body" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="undercarriage" label="起落架" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.undercarriage" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="11">
                <el-form-item prop="tail" label="机尾" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.tail" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="lamp" label="灯" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.lamp" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="11">
                <el-form-item prop="other" label="其他" :label-width="fromLabWidth">
                  <el-radio-group v-model="baseInfo.other" size="mini" :disabled="disable3">
                    <el-radio-button :label="-1">超标</el-radio-button>
                    <el-radio-button :label="0">未超标</el-radio-button>
                    <el-radio-button :label="1">无损伤</el-radio-button>
                    <el-radio-button :label="2">无</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="21">
                <el-form-item prop="otherDescribe" label="其他描述" :label-width="fromLabWidth">
                  <el-input placeholder="其他信息描述" type="textarea" :autosize="{ minRows: 2, maxRows: 5}" maxlength="300"
                    v-model="baseInfo.otherDescribe" show-word-limit :disabled="disable3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>


          </el-form>

        </el-tab-pane>
        <el-tab-pane label="送检信息" name="sendInspection">
          <el-form :model="baseInfo">

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="sendInspection" label="被击部件/送检" :label-width="fromLabWidth">
                  <el-input v-model="baseInfo.sendInspection" placeholder="请输入送检部件说明" :disabled="disable3"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item prop="detectionResult" label="检测结果" :label-width="fromLabWidth">
                  <el-input v-model="baseInfo.detectionResult" placeholder="请输入检测结果" :disabled="disable3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="remarks" label="说明" :label-width="fromLabWidth">
                  <el-input type="textarea" v-model="baseInfo.remarks" placeholder="请输入说明描述" :autosize="{ minRows: 3, maxRows: 5}"
                    maxlength="300" show-word-limit :disabled="disable3"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item prop="consequences" label="后果" :label-width="fromLabWidth">
                  <el-input type="textarea" v-model="baseInfo.consequences" placeholder="请输入后果描述" :autosize="{ minRows: 3, maxRows: 5}"
                    maxlength="300" show-word-limit :disabled="disable3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="被击部位" :label-width="fromLabWidth">
                  <el-upload class="upload-demo" :action="hitUrl + baseInfo.eventNo" :headers="httpHeader" :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove" :file-list="baseInfo.hitImgList" :on-success='uploadCallback' pre
                    list-type="picture" accept="image/jpeg, image/png" :before-upload="beforeAvatarUpload" multiple
                    :disabled="disable3">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5MB</div>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="残留物/送检" :label-width="fromLabWidth">
                  <el-upload class="upload-demo" :action="inspectionUrl + baseInfo.eventNo" :headers="httpHeader"
                    :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="baseInfo.inspectionImgList"
                    :on-success='uploadCallback' list-type="picture" accept="image/jpeg, image/png" :before-upload="beforeAvatarUpload"
                    multiple :disabled="disable3">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5MB</div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

        </el-tab-pane>
        <el-tab-pane label="影响及损失" name="influence">
          <el-form :model="baseInfo">
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item prop="flightImpact" label="对飞行的影响" :label-width="fromLabWidth">
                  <el-select clearable placeholder="对飞行的影响" v-model="baseInfo.flightImpact" filterable :disabled="disable3">
                    <el-option v-for="item in Config.flightImpactOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="损失信息" :label-width="fromLabWidth">
                  <p style="margin-top: 0px;">航空器停场时间 <el-input v-model="baseInfo.lossTime" class='wb' style="width: 50px;"
                      size="mini" :disabled="disable3"></el-input> 小时，修理或替换的估计损失 <el-input class='wb' v-model="baseInfo.estimateAmount"
                      style="width: 90px;" size="mini" :disabled="disable3"></el-input> 元人民币，其他估计损失（如收益、燃油、旅馆费用等损失）<el-input
                      v-model="baseInfo.otherLossAmount" style="width: 90px;" size="mini" :disabled="disable3"></el-input>
                    元人民币 </p>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="备注及其他" name="others">
          <el-form :model="baseInfo">
            <el-row :gutter="20">
              <el-col :span="22">
                <el-form-item label="备注" prop="memo" :label-width="fromLabWidth">
                  <el-input type="textarea" placeholder="备注信息" :disabled="disable3" :autosize="{ minRows: 4, maxRows: 7}"
                    v-model="baseInfo.memo" maxlength="1000" show-word-limit>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="结果" name="result">

          <el-form :model="baseInfo">
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="航空器维修部门" prop="maintenanceDepartment" :label-width="fromLabWidth">
                  <el-input v-model="baseInfo.maintenanceDepartment" :disabled="disable3"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="机场部门" prop="airportDepartment" :label-width="fromLabWidth">
                  <el-input v-model="baseInfo.airportDepartment" :disabled="disable3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="日期" prop="repairDate" :label-width="fromLabWidth">
                  <el-date-picker v-model="baseInfo.repairDate" :disabled="disable3" align="right" type="date"
                    placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 100%;">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="接报时间" prop="callTime" :label-width="fromLabWidth">
                  <el-time-picker v-model="baseInfo.callTime" :disabled="disable3" :picker-options="{
                        selectableRange: '00:00:00 - 23:59:59'
                      }"
                    placeholder="接报时间" style="width: 100%;">
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="维修人员签字" prop="maintenanceDepartment" :label-width="fromLabWidth">
                  <img style="width: 100%;" :src='baseInfo.repairSignUrl.startsWith("data")?baseInfo.repairSignUrl:"data:image/png;base64," + baseInfo.repairSignUrl' v-if="'' != baseInfo.repairSignUrl && null != baseInfo.repairSignUrl" />
                  <el-button type="primary" v-else @click='openSignDialog("repairSign")' size="mini" :disabled="disable3">点击签名</el-button>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="到达时间" prop="arriveTime" :label-width="fromLabWidth">
                  <el-time-picker :disabled="disable3" v-model="baseInfo.arriveTime" :picker-options="{
                        selectableRange: '00:00:00 - 23:59:59'
                      }"
                    placeholder="到达时间" style="width: 100%;">
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11" :offset='11'>
                <el-form-item label="日期" prop="signDate" :label-width="fromLabWidth">
                  <el-date-picker v-model="baseInfo.signDate" align="right" type="date" placeholder="选择日期" :disabled="disable3"
                    value-format="yyyy-MM-dd" style="width: 100%;">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11" :offset='11'>
                <el-form-item label="机场人员签字" :label-width="fromLabWidth">
                  <img style="width: 100%;" :src='baseInfo.airPortSignUrl.startsWith("data")?baseInfo.airPortSignUrl:"data:image/png;base64," + baseInfo.airPortSignUrl' v-if="'' != baseInfo.airPortSignUrl && null != baseInfo.airPortSignUrl" />
                  <el-button type="primary" v-else @click='openSignDialog("airPortSign")' size="mini" :disabled="disable3">点击签名</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

        </el-tab-pane>
      </el-tabs>

    </el-dialog>
    <el-dialog :visible.sync="imgDialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog title="签名" :visible.sync="signDialogVisible" @close='cleanCanvas'>
      <vue-esign ref="esign" :width='800' :height='300' :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"
        :bgColor.sync="bgColor" />

      <span slot="footer" class="dialog-footer">
        <el-button @click="signDialogVisible = false">取 消</el-button>
        <el-button type="info" @click="handleReset">清空画板</el-button>
        <el-button type="primary" @click="handleGenerate">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created() {
      this.initTime();
      this.getList();
    },
    methods: {
      search(){
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.getList();
      },
      initTime() {
        var date = new Date();
        this.searchInfo.time[0] = new Date(date.setDate(date.getDate() - 7));
        this.searchInfo.time[1] = new Date();
      },
      openSignDialog(data) {
        this.signType = data;
        this.signDialogVisible = true;
      },
      cleanCanvas() {
        this.handleReset();
      },
      handleReset() {
        this.$refs.esign.reset()
      },
      handleGenerate() {
        this.$refs.esign.generate().then(res => {
          if ("airPortSign" === this.signType) {
            this.baseInfo.airPortSignUrl = res;
          } else if ("repairSign" === this.signType) {
            this.baseInfo.repairSignUrl = res;
          }
          this.updateInfo();
          this.signDialogVisible = false;
        }).catch(err => {
          alert(err) // 画布没有签字时会执行这里 'Not Signned'
        })
      },
      async updateInfo() {
        this.preventionDbClick = true;
        var {
          data: res
        } = await this.$http.post("/birdStrike/edit", this.baseInfo);
        this.preventionDbClick = false;
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success("数据修改成功");
        this.getList();
      },
      updateBaseInfo() {
        this.updateButton = true;
        this.otherButton = false;
        this.disable1 = true;
        this.disable2 = false;
        this.disable3 = false;
      },
      editBaseInfo() {
        this.updateInfo();
        this.otherButton = false;
        this.updateButton = true;

        this.dialogVisible = false;
      },
      uploadCallback(response, file, fileList) {
        if (!response.success) {
          this.$message.error(response.message);
          return;
        }
        file.id = response.result.id;
        this.getList();
      },

      async saveBaseInfo() {
        
        this.baseInfo.eventDate = this.moment(this.baseInfo.eventDate).format("YYYY-MM-DD HH:mm:ss")
        this.baseInfo.eventTime = this.moment(this.baseInfo.eventTime).format("YYYY-MM-DD HH:mm:ss")
        var {
          data: res
        } = await this.$http.post("/birdStrike/add/base", this.baseInfo);
        if ("SUCCESS" != res.code) {
          this.$message.error(res.message);
          return;
        }
        this.preventionDbClick = true;
        this.baseInfo.eventNo = res.result;
        this.disable1 = true;
        this.disable2 = true;
        this.disable3 = false;
        this.preventionDbClick = false;
        this.baseButton = false;
        this.otherButton = true;
        this.$message.success("请填写详细信息或稍后补充");
        this.getList();
      },
      handleChange(){

      },
      closeDiaLog() {
        this.$refs.BaseInfoRef.resetFields();
        this.$refs.detailInfoRef.resetFields();
        this.$refs.collectionRef.resetFields();
      },
      editDetailDialog(rowData) {
        this.baseInfo = JSON.parse(JSON.stringify(rowData));
        this.disable1 = true;
        this.disable2 = false;
        this.disable3 = false;
        this.baseButton = false;
        this.updateButton = true;
        this.otherButton = false;
        this.activeName = 'detail';
        this.dialogTitle = "修改鸟击事件";
        this.dialogVisible = true;
        this.preventionDbClick = false;
      },
      openDetailDialog(rowData) {
        this.baseInfo = JSON.parse(JSON.stringify(rowData));
        this.disable1 = true;
        this.disable2 = true;
        this.disable3 = true;
        this.baseButton = false;
        this.updateButton = false;
        this.otherButton = true;
        this.activeName = 'detail';
        this.dialogTitle = "修改鸟击事件";
        this.dialogVisible = true;
        this.preventionDbClick = false;
      },
      async getList() {
        this.loading = true;
        if(this.searchInfo.time.length == 2){
          this.searchInfo.time[0] = this.moment(this.searchInfo.time[0]).format('YYYY-MM-DD HH:mm:ss');
          this.searchInfo.time[1] = this.moment(this.searchInfo.time[1]).format('YYYY-MM-DD HH:mm:ss');
        }
        var {
          data: res
        } = await this.$http.post("/birdStrike/list", this.searchInfo);
        this.loading = false;
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.pageInfo = res.result;
      },
      createBirdStrikeEvent() {
        this.preventionDbClick = false;
        this.baseButton = true;
        this.tabsVisble = false;
        this.disable1 = false;
        this.disable2 = false;
        this.updateButton = false;
        this.otherButton = false;
        this.activeName = 'detail';
        this.dialogTitle = "创建鸟击事件";
        this.dialogVisible = true;
        this.baseInfoClear();
      },
      resetSearchInfo() {
        this.$refs.birdStrikeSearchFormRef.resetFields();
        this.getList();
      },
      getCurrentTime() {
        var date = new Date();
        return date.getHours() + ":" + date.getMinutes();
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 5;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 5MB!');
        }
        return isJPG && isLt2M;
      },
      async handleRemove(file, fileList) {
        var {
          data: res
        } = await this.$http.delete("/upload/del/" + file.id);
        if (!res.success) {
          this.$message.error(res.message);
          fileList.push(file);
          return;
        }

        this.$message.success("删除图片成功");

      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.imgDialogVisible = true;
      },
      handleSizeChange(val) {
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.searchInfo.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.searchInfo.page = val;
        this.getList();
      },

      baseInfoClear(){
         this.baseInfo = {
          id: '',
          no: '',
          enterprises: '',
          model: '',
          regNumber: '',
          eventDate: new Date(),
          flightNumber: '',
          flightStart: '',
          flightEnd: '',
          birdStrike: 1,
          eventNo: '',
          airspeed: '',
          heightUnit: 0,
          height: '',
          eventTime: new Date(),
          timeNode: '',
          waring: 1,
          airportName: '',
          eventPlace: '',
          eventPlaceLocation:[],
          runwayNo: '',
          eventPlaceProvince: '',
          eventPlaceCity: '',
          residue: '',
          collection: '',
          flightImpact: '',
          flightPhase: '',
          weather: '',
          precipitation: '',
          impactSpecies: '',
          shape: '',
          number: '',
          hit: '',
          lossTime: '',
          estimateAmount: '',
          otherLossAmount: 0,
          selfResponsibility: '',
          sendInspection: '',
          detectionResult: '',
          consequences: '',
          remarks: '',
          memo: '',
          createStaffNo: '',
          repairDate: '',
          callTime: '',
          arriveTime: '',
          signDate: '',
          maintenanceDepartment: '',
          airportDepartment: '',
          radar: 2,
          windshield: 2,
          head: 2,
          engine1: 2,
          engine2: 2,
          engine3: 2,
          engine4: 2,
          propeller: 2,
          wing: 2,
          body: 2,
          undercarriage: 2,
          tail: 2,
          lamp: 2,
          other: 2,
          otherDescribe: '',
          hitUrlList: [],
          inspectionUrlList: [],
          repairSignUrl: '',
          airPortSignUrl: ''
        }
      }
    },
    data() {
      return {
        pageInfo: {
          firstPage: false,
          lastPage: false,
          listResult: [],
          navigatepageNums: [],
          pageCount: 0,
          total: 0
        },
        signType: '',
        signDialogVisible: false,
        lineWidth: 6,
        lineColor: '#000000',
        bgColor: '',
        resultImg: '',
        isCrop: false,
        defaultPage: 1,
        httpHeader: {
          token: window.localStorage.getItem("token")
        },
        imgDialogVisible: false,
        dialogImageUrl: '',
        otherButton: false,
        loading: false,
        fileList: [],
        hitUrl: this.Config.HIT_URL,
        inspectionUrl: this.Config.INSPECTION_URL,
        chinaProp:{
          value:"code",
          label:"name",
          children:"cities"
        },
        baseInfo: {
          id: '',
          no: '',
          enterprises: '',
          model: '',
          regNumber: '',
          eventDate: new Date(),
          flightNumber: '',
          flightStart: '',
          flightEnd: '',
          birdStrike: 1,
          eventNo: '',
          airspeed: '',
          heightUnit: 0,
          height: '',
          eventTime: new Date(),
          timeNode: '',
          waring: 1,
          airportName: '',
          eventPlace: '',
          eventPlaceLocation:[],
          runwayNo: '',
          eventPlaceProvince: '',
          eventPlaceCity: '',
          residue: '',
          collection: '',
          flightImpact: '',
          flightPhase: '',
          weather: '',
          precipitation: '',
          impactSpecies: '',
          shape: '',
          number: '',
          hit: '',
          lossTime: '',
          estimateAmount: '',
          otherLossAmount: 0,
          selfResponsibility: '',
          sendInspection: '',
          detectionResult: '',
          consequences: '',
          remarks: '',
          memo: '',
          createStaffNo: '',
          repairDate: '',
          callTime: '',
          arriveTime: '',
          signDate: '',
          maintenanceDepartment: '',
          airportDepartment: '',
          radar: 2,
          windshield: 2,
          head: 2,
          engine1: 2,
          engine2: 2,
          engine3: 2,
          engine4: 2,
          propeller: 2,
          wing: 2,
          body: 2,
          undercarriage: 2,
          tail: 2,
          lamp: 2,
          other: 2,
          otherDescribe: '',
          hitUrlList: [],
          inspectionUrlList: [],
          repairSignUrl: '',
          airPortSignUrl: ''
        },
        updateButton: false,
        preventionDbClick: false,
        disable1: false,
        disable2: false,
        disable3: false,
        baseButton: true,
        tabsVisble: false,
        activeName: 'detail',
        fromLabWidth: "120px",
        dialogTitle: '',
        dialogVisible: false,
        birdStrikeList: [],
        searchInfo: {
          time: [],
          eventNo: '',
          birdStrike: '',
          flightNumber: '',
          model: '',
          flightPhase: '',
          page: 1,
          pageSize: 10
        },
        birdStrikeOption: [{
            value: true,
            label: "是"
          },
          {
            value: false,
            label: "否"
          },
        ],

      }
    }
  }
</script>

<style>
  .input-select .el-select .el-input {
    width: 80px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .myUpload .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .myUpload .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }

  .myUpload .avatar {
    width: 80px;
    height: 80px;
    display: block;
  }
</style>
