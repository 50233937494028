<template>
  <el-container class='home_container'>
    <el-header>
      <div class="logo">
        <img @click="goDashboard()" style="cursor: pointer;" src="../assets/logo.png" alt="" />
        <span>{{airportName}}飞行区智能管理系统</span>
      </div>
      <!-- <el-button type="infor" @click='logout'>注销</el-button> -->
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          欢迎您！{{name}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command='refresh'>更新数据</el-dropdown-item>
          <el-dropdown-item command='logout' divided>注销</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-container>
      <el-aside :width="fold?'64px':'200px'">
        <div class="toggle-button">
          <i :class="fold?eliconsunfold:eliconsfold" @click="tiggle" />
        </div>
        <el-menu background-color="#333744" text-color="#fff" router active-text-color="#409eff" :collapse='fold'
          :collapse-transition='false' unique-opened :default-active='menuActive'>
          <el-submenu :index="menu.id + ''" v-for="menu in menuList" :key='menu.id'>
            <template slot="title">
              <i :class="'iconfont ' + menu.icon"></i>
              <span>{{ menu.name }}</span>
            </template>
            <el-menu-item :index="menuItem.path" v-for="menuItem in menu.children" :key='menuItem.id' @click='avtive(menuItem.path)'>
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>{{ menuItem.name }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>

      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  export default {
    created() {
      this.name = window.localStorage.getItem("userName");
      this.airportName = window.localStorage.getItem("airportName");
      this.getMenuList();
      this.getStaticData();
      this.getRoleInfo();
      this.getInspectionUser();
      this.getBird();
      this.menuActive = window.sessionStorage.getItem("active");
      // this.airportName = this.Config.AIR_PORT_NAME;
      this.refresh();
    },
    data() {
      return {
        airportName:"",
        name: '',
        eliconsfold: 'el-icon-s-fold',
        eliconsunfold: 'el-icon-s-unfold',
        fold: false,
        menuList: [],
        menuActive:"",
      };
    },
    methods: {
      goDashboard(){
        this.$router.push("/welcome");
      },
      refresh(){
        this.name = window.localStorage.getItem("userName");
        this.getMenuList();
        this.getStaticData();
        this.getRoleInfo();
        this.getInspectionUser();
        this.getBird();
        this.menuActive = window.sessionStorage.getItem("active");
        this.Config.httpHeader = {
          token: window.localStorage.getItem("token")
        };
      },
      avtive(index){
        window.sessionStorage.setItem("active", index);
        this.menuActive = index;
      },
      async getRoleInfo() {
        var {
          data: res
        } = await this.$http.get("/static/role/all");
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.Config.occupationOptions = res.result['list'];
        this.Config.occupationDisplay = res.result['json'];
      },
      async getInspectionUser() {
        var {
          data: res
        } = await this.$http.get("/static/inspector/all");
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.Config.inspectorOptions = res.result['list'];
        this.Config.inspectorDisplay = res.result['json'];
      },
      async getBird() {
        var {
          data: res
        } = await this.$http.get("/static/bird/all");
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.Config.birdOptions = res.result['list'];
        this.Config.birdDisplay = res.result['json'];
      },
      async getStaticData() {
        var {
          data: res
        } = await this.$http.get("/static/all");
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        var dataList = res.result.data;
        this.Config.groupOptions = dataList['2'];
        this.Config.eduOptions = dataList['3'];
        this.Config.drivingLicenceOptions = dataList['4'];
        this.Config.groupDisplay = dataList['display_2'];
        this.Config.eduDisplay = dataList['display_3'];
        this.Config.drivingLicenceDisplay = dataList['display_4'];
        this.Config.threatenOptions = dataList['5']
        this.Config.threatenDisplay = dataList['display_5']
        this.Config.flightDirectionOptions = dataList['6']
        this.Config.flightDirectionDisplay = dataList['display_6']
        this.Config.regionOptions = dataList['7'];
        this.Config.regionDisplay = dataList['display_7'];
        this.Config.reasonOptions = dataList['8'];
        this.Config.reasonDisplay = dataList['display_8']
        this.Config.effectOptions = dataList['9']
        this.Config.effectDisplay = dataList['display_9']
        this.Config.towerOptions = dataList['10'];
        this.Config.towerDisplay = dataList['display_10']
        this.Config.hightOptions = dataList['11']
        this.Config.hightDisplay = dataList['display_11']
        this.Config.landingDirectionOptions = dataList['12']
        this.Config.landingDirectionDisplay = dataList['display_12']
        this.Config.behaviorOptions = dataList['13']
        this.Config.behaviorDisplay = dataList['display_13']
        this.Config.meansOptions = dataList['14']
        this.Config.meansDisplay = dataList['display_14']
        this.Config.pedestaOptions = dataList['15']
        this.Config.pedestaDisplay = dataList['display_15']
        this.Config.countOptions = dataList['16']
        this.Config.countDisplay = dataList['display_16']
        this.Config.treatmentOptions = dataList['17']
        this.Config.treatmentDisplay = dataList['display_17']
        this.Config.boxOptions = dataList['18']
        this.Config.boxDisplay = dataList['display_18']
        this.Config.hazardRankingOptions = dataList['19']
        this.Config.hazardRankingDisplay = dataList['display_19']
        this.Config.timeNodeOptions = dataList['20']
        this.Config.timeNodeDisplay = dataList['display_20']
        this.Config.eventPlaceOptions = dataList['21'];
        this.Config.eventPlaceDisplay = dataList['display_21']
        this.Config.flightPhaseOptions = dataList['22']
        this.Config.flightPhaseDisplay = dataList['display_22']
        this.Config.airOptions = dataList['23']
        this.Config.airDisplay = dataList['display_23']
        this.Config.precipitationOptions = dataList['24']
        this.Config.precipitationDisplay = dataList['display_24']
        this.Config.residueOptions = dataList['25']
        this.Config.residueDisplay = dataList['display_25']
        this.Config.shapeOptions = dataList['26']
        this.Config.shapeDisplay = dataList['display_26']
        this.Config.flightImpactOptions = dataList['27']
        this.Config.flightImpactDisplay = dataList['display_27']
        this.Config.weatherOptions = dataList['28']
        this.Config.weatherDisplay = dataList['display_28']

      },
      tiggle() {
        this.fold = !this.fold;
      },
      async getMenuList() {
        var {
          data: res
        } = await this.$http.get("/permission/getPermission");
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.menuList = res.result['menuList'];
        window.localStorage.setItem("buttonsList", res.result['buttonsList'])
        this.Config.buttonList = res.result['buttonsList'];
      },
      handleCommand(command) {
        if ('logout' === command)
          this.logout();
        if('refresh' === command){
          this.refresh();
          this.$message.success("公共数据更新成功");
        }


      },
      logout() {

        this.$confirm('是否确认登出操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.localStorage.clear();
          window.sessionStorage.clear();
          this.$message.success('登出成功');
          this.$router.push('/login');
        }).catch(
          () => {
            console.log("cancel")
          }
        );

      }
    }
  }
</script>

<style lang="less" scoped>
  .el-header {
    background-color: #373d41;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 20px;

    >div {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }
  }

  .el-aside {
    background-color: #333744;
  }

  .el-main {
    background-color: #eaedf1;
  }

  .home_container {
    height: 100%;
  }

  .logo {
    img {
      height: 100%;
      width: 50px;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  .el-menu {
    border-right: 0
  }

  .el-dropdown-link {
    color: white;
  }

  .welcome {
    color: white;
  }

  .iconfont {
    margin-right: 10px;
  }

  .toggle-button {
    background-color: #4a5064;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2em;

    i {
      cursor: pointer;
    }
  }
</style>
