<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>智能硬件</el-breadcrumb-item>
      <el-breadcrumb-item>虫情设备</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card shadow="hover">
      <el-row>
        <el-col :span="14">
          <el-row>
            <el-col :span="6" :offset="2">
              <el-radio-group v-model="choiceTime" style="margin-bottom: 30px;" size="mini" @change="getBar">
                <el-radio-button label="DAY">今日</el-radio-button>
                <el-radio-button label="WEEK">一周</el-radio-button>
                <el-radio-button label="MONTH">半月</el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div id="ring" style="width: 100%; height: 300px;" v-loading="ringLoading">ring</div>
            </el-col>
            <el-col :span="12">
              <div id="bar" style="width: 100%; height: 300px;" v-loading="barLoading">bar</div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="10">
          <el-table :data="list" style="width: 100%;" border v-loading="listLoading">
            <el-table-column type="index" label="#" align="center"></el-table-column>
            <el-table-column label="设备编号" align="center" prop="imei">
              <template slot-scope='scope'>
                <el-tag>{{scope.row.imei}}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="设备名称" align="center" prop="deviceName">
              <template slot-scope='scope'>
                <el-tag>{{scope.row.deviceName}}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope='scope'>
                <el-tooltip content="查看状态" placement="top" :enterable="false">
                  <el-button type="warning" icon="el-icon-notebook-2" size="mini" @click='detailDialog(scope.row)'>
                  </el-button>
                </el-tooltip>
                <el-tooltip content="设置" placement="top" :enterable="false">
                  <el-button type="primary" icon="el-icon-setting" size="mini" @click='settingDialog(scope.row)'>
                  </el-button>
                </el-tooltip>
                <el-tooltip content="查看数据" placement="top" :enterable="false">
                  <el-button type="success" icon="el-icon-s-order" size="mini" @click='deviceRecord(scope.row.imei)'>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog :visible.sync='statusDialogVisible' title="设备状态" @close="closeStatusDialog">
      <el-row :gutter="10" align="top">
        <el-col :span="4" :offset='2'>
          <el-card shadow="hover">
            <div class="bigIcon">
              <i class="iconfont icon-timer"></i>
            </div>
            <div class="fieldValue">{{ statusBean.ts == 0? "光控" : "时控"}}</div>
            <div class='fieldName'>定时模式</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover">
            <div class="bigIcon">
              <i class="iconfont icon-WeatherSunny"></i>
            </div>
            <div class="fieldValue">{{statusBean.lps == 0 ? "正常" : "光控"}}</div>
            <div class='fieldName'>光控状态</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover">
            <div class="bigIcon">
              <i class="iconfont icon-wendu"></i>
            </div>
            <div class="fieldValue">{{statusBean.tps == 0 ? "正常" : "温控"}}</div>
            <div class='fieldName'>温控状态</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover">
            <div class="bigIcon">
              <i class="iconfont icon-WeatherSnow"></i>
            </div>
            <div class="fieldValue">{{statusBean.rps == 0 ? "正常" : "雨控"}}</div>
            <div class='fieldName'>雨控状态</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover">
            <div class="bigIcon">
              <i class="iconfont icon-paishui"></i>
            </div>
            <div class="fieldValue">{{ statusBean.gs == 0 ? "排水" : "落虫"}}</div>
            <div class='fieldName'>通道状态</div>
          </el-card>
        </el-col>
      </el-row>
      <el-row>
        <div style="height: 20px;"></div>
      </el-row>
      <el-row :gutter="10" align="top">
        <el-col :span="4" :offset='2'>
          <el-card shadow="hover">
            <div class="bigIcon">
              <i class="iconfont icon-jiareban"></i>
            </div>
            <div class="fieldValue">{{statusBean.hs == 0 ? "正常" : "加热"}}</div>
            <div class='fieldName'>加热状态</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover">
            <div class="bigIcon">
              <i class="iconfont icon-Door"></i>
            </div>
            <div class="fieldValue">{{statusBean.upds == 0 ? "关闭" : "打开"}}</div>
            <div class='fieldName'>上仓门状态</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover">
            <div class="bigIcon">
              <i class="iconfont icon-Door"></i>
            </div>
            <div class="fieldValue">{{statusBean.dnds == 0 ? "关闭" : "打开"}}</div>
            <div class='fieldName'>下仓门状态</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover">
            <div class="bigIcon">
              <i class="iconfont icon-wifi"></i>
            </div>
            <div class="fieldValue">{{statusBean.csq == null ? 0:statusBean.csq}}</div>
            <div class='fieldName'>信号强度</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover">
            <div class="bigIcon">
              <i class="iconfont icon-mobile"></i>
            </div>
            <div class="fieldValue">{{statusBean.dver == null ? 0:statusBean.dver.length > 6?statusBean.dver.substring(0, 6):statusBean.dver}}</div>
            <div class='fieldName'>版本型号</div>
          </el-card>
        </el-col>
      </el-row>

      <div slot='footer'>
        <el-button @click="closeStatusDialog">取 消</el-button>
        <el-button type="primary" @click="setting()">设 置</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync='settingDialogVisible' title="设备设置" @close="closeSettingDialog">
      <el-form :model="statusBean" ref='settingRef' label-width="120px">
        <el-row :gutter="20">
          <el-col :span="20" :offset="2">
            <el-form-item prop="ts" label="工作模式" align="center">
              <el-select clearable placeholder="请选择工作模式" v-model='statusBean.ts' filterable>
                <el-option :key='0' :value="0" label="光控"></el-option>
                <el-option :key='1' :value="1" label="时控"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="statusBean.ts == 1">
          <el-col :span="20" :offset="2">
            <el-form-item label="时间范围" align="center">
              <template>
                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-time-select placeholder="起始时间" v-model="statusBean.st" :picker-options="{
                     start: '00:00',
                     step: '01:00',
                     end: '23:00'
                   }">
                    </el-time-select>
                  </el-col>
                  <el-col :span="10">
                    <el-time-select placeholder="结束时间" v-model="statusBean.et" :picker-options="{
                     start: '00:00',
                     step: '01:00',
                     end: '23:00'
                   }">
                    </el-time-select>
                  </el-col>

                </el-row>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" :offset='2'>
            <el-form-item prop="collt" label="落虫时间 (分钟)" align="center">
              <el-slider v-model="statusBean.collt" :min='5' :max="20" :step="1" show-stops>
              </el-slider>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" :offset='2'>
            <el-form-item prop="htim" label="加热时间 (分钟)" align="center">
              <el-slider v-model="statusBean.htim" :min='5' :max="20" :step="1" show-stops>
              </el-slider>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" :offset='2'>
            <el-form-item prop="hst" label="加热温度（℃）" align="center">
              <el-slider v-model="statusBean.hst" :min='75' :max="125" :step="1" show-stops>
              </el-slider>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" :offset='2'>
            <el-form-item prop="tph" label="加热仓温度上限（℃）" align="center">
              <el-slider v-model="statusBean.tph" :min='40' :max="70" :step="1" show-stops>
              </el-slider>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" :offset='2'>
            <el-form-item prop="tpl" label="加热仓温度下限（℃）" align="center">
              <el-slider v-model="statusBean.tpl" :min='-5' :max="10" :step="1" show-stops>
              </el-slider>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" :offset='2'>
            <el-form-item prop="datt" label="数据上传时间间隔（分钟）" align="center">
              <el-slider v-model="statusBean.datt" :min='5' :max="60" :step="1" show-stops>
              </el-slider>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot='footer'>
        <el-button @click="closeSettingDialog">取 消</el-button>
        <el-button type="primary" @click="saveSetting()">确 定</el-button>
      </div>
    </el-dialog>
    <div style="height: 12px;"></div>
    <el-card>
      <el-form :model="searchInfo" ref='repelSearchRef'>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="time">
              <el-tooltip content="搜索的时间段" placement="top" :enterable="false">
                <el-date-picker v-model="searchInfo.time" type="daterange" align="right" unlink-panels
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                  style="width: 100%;">
                </el-date-picker>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" plain @click='search'>搜索</el-button>
            <el-button type="info" plain @click='resetSearchInfo'>重置</el-button>
            <el-button type='infor' plain @click='exportExcel'>导出</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="pageInfo.listResult" border stripe v-loading='detailListLoading'>
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column prop="imei" label="imei" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ scope.row.imei }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="昆虫名称" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ scope.row.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="count" label="数量" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ scope.row.count }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="images" label="原始图片" align="center">
          <template slot-scope='scope'>
            <el-tag @click='previewImage(scope.row.images)'>查看</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="resultImages" label="分析图片" align="center">
          <template slot-scope='scope'>
            <el-tag @click='previewImage(scope.row.resultImages)'>查看</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="发现时间" align="center">
          <template slot-scope='scope'>
            <el-tag>{{ scope.row.time }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :page-sizes="[1, 5, 10, 20, 30, 40, 100]" :page-size="searchInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :current-page.sync='defaultPage' :total="pageInfo.total">
      </el-pagination>
      <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imageList" />
    </el-card>
  </div>
</template>

<script>
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
  import * as echarts from 'echarts'
  import { export_json_to_excel } from '../../plugins/Export2Excel.js'
  export default {
    components: {
      ElImageViewer
    },
    data() {
      return {
        showViewer: false,
        defaultPage: 1,
        ringLoading: false,
        barLoading: false,
        listLoading: false,
        detailListLoading: false,
        statusDialogVisible: false,
        settingDialogVisible: false,
        list: [],
        bean: {},
        timeRange: [],
        statusBean: {},
        ring: "",
        bar: "",
        ringDom: null,
        barDom: null,
        choiceTime: "DAY",
        imageList: null,
        url: '',
        searchInfo: {
          time: [],
          name: '',
          page: 1,
          imei: '',
          pageSize: 10
        },
        tempPage:1,
        tempPageSize:10,
        pageInfo: {
          firstPage: false,
          lastPage: false,
          listResult: [],
          navigatepageNums: [],
          pageCount: 0,
          total: 0
        },
      }
    },
    created() {
      this.getList();
      this.initTime();
      this.search();
    },
    mounted() {
      this.chatsData("DAY", null);
    },
    methods: {
      async exportExcel(){
        this.tempPage = this.searchInfo.page;
        this.tempPageSize = this.searchInfo.pageSize;
        this.searchInfo.page = 1;
        this.searchInfo.pageSize = 10000;
        console.log(this.searchInfo);
        var {
          data: res
        } = await this.$http.post("/insect/analyzes/list", this.searchInfo);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.searchInfo.page = 1;
        this.searchInfo.pageSize = this.tempPageSize;
        this.search();
        this.getExcel(res.result.listResult);
      },
      getExcel(res) {
        const tHeader = ['发现时间', 'imei', '昆虫名称', '数量', '原始图片', '分析图片']
        const filterVal = ['time', 'imei', 'name', 'count', 'images', 'resultImages']
        const list = res
        const data = this.formatJson(filterVal, list)
        export_json_to_excel(tHeader, data, '虫情_' + this.searchInfo.time[0] + '_' + this.searchInfo.time[1], '虫情详情')
      },
      formatJson(filterVal, jsonData) {
          return jsonData.map(v => {
            return filterVal.map(j => {
              if(j == 'images' || j == 'resultImages')
                return v[j][0]['url'];
              return v[j];
            })
          })
      },
      onPreview() {
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },
      previewImage(list) {
        var images = new Array()
        list.forEach(function(element) {
          images.push(element.url);
        })
        if (images.length <= 0) {
          this.$message.error("还没有图片");
          return;
        }
        this.imageList = images;
        this.onPreview();
      },
      async deviceRecord(rowData) {
        this.searchInfo.imei = rowData;
        this.detailListLoading = true;
        if(this.searchInfo.time.length == 2){
          this.searchInfo.time[0] = this.moment(this.searchInfo.time[0]).format('YYYY-MM-DD HH:mm:ss');
          this.searchInfo.time[1] = this.moment(this.searchInfo.time[1]).format('YYYY-MM-DD HH:mm:ss');
        }
        var {
          data: res
        } = await this.$http.post("/insect/analyzes/list", this.searchInfo);
        this.detailListLoading = false;
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.pageInfo = res.result;
      },
      search() {
        this.deviceRecord('');
      },
      initTime() {
        var date = new Date();
        this.searchInfo.time[0] = new Date(date.setDate(date.getDate() - 7));
        this.searchInfo.time[1] = new Date();
      },
      handleSizeChange(val) {
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.searchInfo.pageSize = val;
        this.search();
      },
      handleCurrentChange(val) {
        this.searchInfo.page = val;
        this.search();
      },
      resetSearchInfo() {
        this.$refs.repelSearchRef.resetFields();
      },
      getBar() {
        this.chatsData(this.choiceTime, null);
      },
      chartWithRing() {
        var dom = document.getElementById("ring");
        if (echarts.getInstanceByDom(dom))
          this.ringDom.dispose();
        this.ringDom = echarts.init(dom);
        this.ringLoading = false;
        this.ringDom.setOption(JSON.parse(this.ring), true);
      },
      chartWithBar() {
        var dom = document.getElementById("bar");
        if (echarts.getInstanceByDom(dom))
          this.barDom.dispose();
        this.barDom = echarts.init(dom);
        this.barLoading = false;
        this.barDom.setOption(JSON.parse(this.bar), true);
      },
      async chatsData(type, imei) {
        this.ringLoading = true;
        this.barLoading = true;
        var {
          data: res
        } = await this.$http.get("/insect/analyzes/charts/" + type + "/" + imei);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        var result = res.result;
        this.bar = result.bar;
        this.ring = result.barPolar;
        this.chartWithBar();
        this.chartWithRing();
      },
      cleanSetting() {
        this.$refs.settingRef.resetFields();
      },
      closeStatusDialog() {
        this.statusDialogVisible = false;
      },
      setting() {
        this.closeStatusDialog();
        this.settingDialog();
      },
      closeSettingDialog() {
        this.settingDialogVisible = false;
      },
      async saveSetting() {
        var {
          data: res
        } = await this.$http.post("/insectAnalyze/config/convert", this.statusBean);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success(res.message);
        this.closeSettingDialog();
      },
      detailDialog(rowData) {
        this.getConfig(rowData);
        this.statusDialogVisible = true;
      },
      async getConfig(rowData) {
        var {
          data: res
        } = await this.$http.get("/insect/analyzes/" + rowData.imei + "/status");
        if (!res.success) {
          this.$message.error(res.message);
          this.statusDialogVisible = false;
          this.settingDialogVisible = false;
          return;
        }
        this.statusBean = res.result;
      },
      async settingDialog(rowData) {
        this.getConfig(rowData);
        this.settingDialogVisible = true;
      },
      async getList() {
        this.listLoading = true;
        var {
          data: res
        } = await this.$http.post("/insectAnalyze/list", this.searchInfo);
        this.listLoading = false;
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.list = res.result.listResult;
      }
    }
  }
</script>

<style>
  .bigIcon {
    text-align: center;
  }

  .bigIcon .iconfont {
    font-size: 40px;

  }

  .fieldName {
    text-align: center;
    font-size: 8px;
  }

  .fieldValue {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }
</style>
