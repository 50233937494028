<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>物种百科</el-breadcrumb-item>
      <el-breadcrumb-item>鸟类信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :model="searchInfo">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item prop="name">
              <el-input placeholder="请输入首字母或名字" v-model="searchInfo.name" clearable @clear="search"
                @keyup.enter.native='search'>
                <el-button slot="append" icon="el-icon-search" @click='search'></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="17">
            <el-button type="primary" v-if="$display('/wiki/bird/add')" @click="openAddDialog">新增鸟类</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="pageInfo.listResult" style="width: 100%" border v-loading="loading">
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column prop="pic" label="图片" align="center" width="80px">
          <template slot-scope='scope'>
            <el-tooltip content="点击查看图片" placement="top" :enterable="false">
              <el-image :src="scope.row.pic" :preview-src-list="scope.row.picList" alt>
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="学名" align="center" width="120px"></el-table-column>
        <el-table-column prop="latinName" label="拉丁学名" align="center" width="200px"></el-table-column>
        <el-table-column prop="alias" label="俗称" align="center" width="120px">
          <template slot-scope='scope'>
            <el-tag>{{ scope.row.alias }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="weight" label="重量" align="center"></el-table-column>
        <el-table-column prop="length" label="身长" align="center"></el-table-column>
        <el-table-column prop="flightAltitude" label="飞行高度" align="center"></el-table-column>
        <el-table-column prop="hazardRanking" label="危害程度" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="270px">
          <template slot-scope='scope'>
            <el-tooltip content="查看详情" placement="top" :enterable="false">
              <el-button type="success" icon="el-icon-notebook-2" size="mini" @click='getBridInfo(scope.row)'></el-button>
            </el-tooltip>
            <el-tooltip content="编辑" placement="top" :enterable="false">
              <el-button v-if="$display('/wiki/bird/edit')" type="primary" icon="el-icon-edit" size="mini" @click='editBirdInfo(scope.row)'></el-button>
            </el-tooltip>
            <el-popconfirm style='margin-left: 10px; margin-right: 10px;' confirmButtonText='确定' cancelButtonText='取消'
              icon="el-icon-info" iconColor="red" title="确定删除这条记录吗？" @onConfirm="deleteBirdInfo(scope.row)">
              <el-button slot="reference" v-if="$display('/wiki/bird/del')" type="danger" icon="el-icon-delete" size="mini"></el-button>
            </el-popconfirm>
            <el-tooltip content="上传照片" placement="top" :enterable="false">
              <el-button  v-if="$display('/wiki/bird/add') || $display('/wiki/bird/edit')" type="primary" size="mini" @click='openUploadDialog(scope.row)'><i class="el-icon-upload"></i></el-button>
            </el-tooltip>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[1, 5, 10, 20, 30, 40, 100]"
        :page-size="searchInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :current-page.sync='defaultPage'
        :total="pageInfo.total">
      </el-pagination>
    </el-card>
    <el-dialog :title="dialogTitle" :visible.sync="dialogvisble" @close="closeBirdInfoDialog">
      <el-form :model="birdInfo" ref='birdInfoRef' :rules='birdInfoRules'>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="name" label="名称" :label-width="formLabelWidth">
              <el-input v-model.trim="birdInfo.name" placeholder="请输入鸟类全名" clearable :disabled="enable1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="monthList" label="活动月份" :label-width="formLabelWidth">
              <el-select v-model="birdInfo.monthList" multiple clearable filterable allow-create default-first-option
                placeholder="请选择月份" style="width:100%" :disabled="enable2">
                <el-option v-for="item in months" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="weight" label="重量" :label-width="formLabelWidth">
              <el-input v-model="birdInfo.weight" placeholder="请输入重量信息" clearable :disabled="enable2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="length" label="身长" :label-width="formLabelWidth">
              <el-input v-model="birdInfo.length" placeholder="请输入长度信息" clearable :disabled="enable2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item prop="flightAltitude" label="飞行高度" :label-width="formLabelWidth">
              <el-select clearable placeholder="飞行高度" v-model="birdInfo.flightAltitude" :disabled="enable2">
                <el-option v-for="item in Config.hightOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="hazardRanking" label="危害程度" :label-width="formLabelWidth">
              <el-select clearable placeholder="危害程度" v-model="birdInfo.hazardRanking" :disabled="enable2">
                <el-option v-for="item in Config.hazardRankingOptions" :key='item.optionNo' :label="item.optionName"
                  :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="23">
            <el-form-item prop="aliasList" label="俗称" :label-width="formLabelWidth">
              <el-tag :key="tag" v-for="tag in birdInfo.aliasList" :closable="!enable2" :disable-transitions="false"
                @close="handleClose(tag)">
                {{tag}}
              </el-tag>
              <el-input :disabled="enable2" style="width:80px;" class="input-new-tag" v-if="inputVisible" v-model="inputValue"
                ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
              </el-input>
              <el-button :disabled="enable2" v-else class="button-new-tag" size="small" :style="inputMarginleft" @click="showInput">+
                俗称</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="22">
            <el-form-item prop="features" label="简介" :label-width="formLabelWidth">
              <el-input :disabled="enable2" type="textarea" :rows='5' placeholder="请输入简介,长度不超过1000字" v-model="birdInfo.features"
                maxlength="1000" show-word-limit clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogvisble = false">取 消</el-button>
        <el-button type="primary" @click="saveBirdInfo" v-show="addButton">确 定</el-button>
        <el-button type="primary" @click="editDialog" v-show="editButton">修 改</el-button>
        <el-button type="primary" @click="updatBirdInfo" v-show="updateButton">保 存</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="imgDialogvisble">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog title="上传图片" :visible.sync="uploadDialogVisible" width="30%">
      <el-upload class="upload-demo" :action="Config.BIRD_UPLOAD_URL + birdInfo.birdNo" :headers="Config.httpHeader"
        :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="birdInfo.imagesInfos" :on-success='uploadCallback'
        pre list-type="picture-card" accept="image/jpeg, image/png" :before-upload="beforeAvatarUpload" multiple :disabled="enable2">
        <i class="el-icon-plus"></i>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过5MB</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created() {
      this.getList();
    },
    methods: {
      openUploadDialog(rowData) {
        this.enable2 = false;
        this.birdInfo = JSON.parse(JSON.stringify(rowData));
        this.uploadDialogVisible = true;
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 5;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 5MB!');
        }
        return isJPG && isLt2M;
      },
      handleSizeChange(val) {
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.searchInfo.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.searchInfo.page = val;
        this.getList();
      },
      uploadCallback(response, file, fileList) {
        if (!response.success) {
          this.$message.error(response.message);
          return;
        }
        file.id = response.result.id;
        this.getList();
      },
      getBridInfo(rowData) {
        this.dialogTitle = '查看详情';
        this.enable1 = true;
        this.enable2 = true;
        this.addButton = false;
        this.editButton = true;
        this.updateButton = false;
        this.birdInfo = JSON.parse(JSON.stringify(rowData));
        this.dialogvisble = true;
      },
      editBirdInfo(rowData) {
        this.birdInfo = JSON.parse(JSON.stringify(rowData));
        this.editDialog();
      },
      async deleteBirdInfo(rowData) {
        var {
          data: res
        } = await this.$http.delete("/wiki/bird/del/" + rowData.birdNo);
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.$message.success("删除成功");
        this.getList();
      },
      closeBirdInfoDialog() {
        this.$refs.birdInfoRef.resetFields();
      },
      async handleRemove(file) {
        var {
          data: res
        } = await this.$http.delete("/upload/del/" + file.id);
        if (!res.success) {
          this.$message.error(res.message);
          this.birdInfo.imagesInfos.push(file);
          return;
        }
        this.$message.success("删除图片成功");
        this.getList();
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.imgDialogvisble = true;
      },
      openAddDialog() {
        this.dialogTitle = '新增鸟种';
        this.enable1 = false;
        this.enable2 = false;
        this.addButton = true;
        this.editButton = false;
        this.updateButton = false;
        this.dialogvisble = true;
      },
      editDialog() {
        this.dialogTitle = '修改鸟种';
        this.enable1 = true;
        this.enable2 = false;
        this.addButton = false;
        this.editButton = false;
        this.updateButton = true;
        this.dialogvisble = true;
      },
      async getList() {
        this.loading = true;
        var {
          data: res
        } = await this.$http.post("/wiki/bird/list", this.searchInfo);
        this.loading = false;
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.pageInfo = res.result;
      },
      updatBirdInfo() {
        this.$refs.birdInfoRef.validate(async (valid) => {
          if (!valid)
            return;
          var {
            data: res
          } = await this.$http.post("/wiki/bird/edit", this.birdInfo);
          if (!res.success) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success("数据修改成功");
          this.getList();
          this.dialogvisble = false;
        })
      },
      saveBirdInfo() {
        this.$refs.birdInfoRef.validate(async (valid) => {
          if (!valid)
            return;
          var {
            data: res
          } = await this.$http.post("/wiki/bird/add", this.birdInfo);
          if (!res.success) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success("数据添加成功");
          this.getList();
          this.dialogvisble = false;
        })
      },
      handleClose(tag) {
        this.birdInfo.aliasList.splice(this.birdInfo.aliasList.indexOf(tag), 1);
      },

      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },

      handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue && this.birdInfo.aliasList.indexOf(inputValue) == -1) {
          this.birdInfo.aliasList.push(inputValue);
        }
        this.inputVisible = false;
        this.inputValue = '';
      },
      search(){
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.getList();
      }
    },
    data() {
      return {
        uploadDialogVisible: false,
        loading: false,
        pageInfo: {
          firstPage: false,
          lastPage: false,
          listResult: [],
          navigatepageNums: [],
          pageCount: 0,
          total: 0
        },
        defaultPage: 1,
        addButton: false,
        editButton: false,
        updateButton: false,
        enable1: false,
        enable2: false,
        birdInfoRules: {
          name: [{
            required: true,
            message: '名字不能为空',
            trigger: 'blur'
          }],
          features: [{
            required: true,
            message: "简介不能为空",
            trigger: 'blur'
          }]
        },
        dialogImageUrl: '',
        imgDialogvisble: false,
        inputMarginleft: "margin-left:10px",
        inputVisible: false,
        inputValue: '',
        uploadHeader: {
          token: window.localStorage.getItem("token"),
        },
        months: ["全年", "一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
        formLabelWidth: "130px",
        dialogvisble: false,
        dialogTitle: '',
        birdInfo: {
          name: '',
          monthList: [],
          aliasList: [],
          alias: '',
          month: '',
          hazardRanking: '',
          flightAltitude: '',
          weight: '',
          length: '',
          features: '',
          pic: '',
          picList: [],
          imagesInfos: []
        },
        birdInfoList: [],
        imageList: [],
        searchInfo: {
          name: '',
          page: 1,
          pageSize: 10
        }
      }
    }
  }
</script>

<style>
  .el-tag+.el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

  .el-card__body .el-card {
    margin-bottom: 20px;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>
