<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <div>
            <el-input placeholder="请输入员工姓名" v-model="searchInfo.userName" clearable @clear="search"
              @keyup.enter.native='search'>
              <el-button slot="append" icon="el-icon-search" @click='search'></el-button>
            </el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <el-button type="success" v-if="$display('/staff/reg')" @click='addDialogFormVisible = true'>新增员工</el-button>
        </el-col>
      </el-row>

      <el-table style="width: 100%" :data="pageInfo.listResult" border stripe v-loading='loading'>
        <el-table-column label="#" type="index" align="center"></el-table-column>
        <el-table-column label="姓名" prop="name" align="center">
        </el-table-column>
        <el-table-column label="管理员" prop="manager" align="center">
          <template slot-scope='scope'>
            <el-tag v-if="scope.row.manager">是</el-tag>
            <el-tag v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="登录名" prop="loginName" align="center">
        </el-table-column>
        <el-table-column label="性别" prop="gender" align="center">
          <template slot-scope='scope'>
            <el-tag v-if="scope.row.gender == 0">女</el-tag>
            <el-tag v-else>男</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="出生日期" prop="birthDate" width="110px" align="center">
        </el-table-column>
        <el-table-column label="班组" prop="staffGroup" align="center">
          <template slot-scope='scope'>
            <p>{{ Config.groupDisplay['dp' + scope.row.staffGroup] }}</p>
          </template>
        </el-table-column>
        <el-table-column label="职务" prop="occupation" align="center">
          <template slot-scope='scope'>
            <p>{{ Config.occupationDisplay[scope.row.occupation] }}</p>
          </template>
        </el-table-column>
        <el-table-column label="学历" prop="education" align="center">
          <template slot-scope='scope'>
            <p>{{ Config.eduDisplay['dp' +scope.row.education] }}</p>
          </template>
        </el-table-column>
        <el-table-column label="驾照类型" prop="drivingLicence" align="center">
          <template slot-scope='scope'>
            <p>{{ Config.drivingLicenceDisplay['dp' + scope.row.drivingLicence] }}</p>
          </template>
        </el-table-column>
        <el-table-column label="持枪资格" prop="holdingGun" align="center">
          <template slot-scope='scope'>
            <el-tag type='warning' v-if="scope.row.holdingGun">有</el-tag>
            <el-tag v-else>无</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="130px" align="center">
          <template slot-scope='scope'>
            <el-tooltip content="修改用户信息" placement="top-start" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" v-if="$display('/staff/edit')" size="mini" @click='openEditDialog(scope.row)'></el-button>
            </el-tooltip>
            <el-tooltip content="删除用户信息" placement="top-start" :enterable="false">
              <el-button type="danger" v-if="$display('/staff/delete')" icon="el-icon-delete" size="mini" @click='delUser(scope.row)'></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[1, 5, 10, 20, 30, 40, 100]"
        :page-size="searchInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :current-page.sync='defaultPage'
        :total="pageInfo.total">
      </el-pagination>
    </el-card>

    <!-- 新增用户dialog -->
    <el-dialog title="添加用户信息" :visible.sync="addDialogFormVisible" @close="closeDialog">
      <el-form :model="userInfo" :rules="userRlues" ref='userInfoRef'>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
              <el-input v-model="userInfo.name" placeholder="真实姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="组别" :label-width="formLabelWidth" prop="staffGroup">
              <el-select clearable placeholder="请选择组别" v-model="userInfo.staffGroup">
                <el-option v-for="item in Config.groupOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="管理员" :label-width="formLabelWidth" prop="manager">
              <el-radio-group v-model="userInfo.manager" size='mini'>
                <el-radio-button :label="true">是</el-radio-button>
                <el-radio-button :label="false">否</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="职务" :label-width="formLabelWidth" prop="occupation">
              <el-select clearable placeholder="请选择职务" v-model="userInfo.occupation">
                <el-option v-for="item in Config.occupationOptions" :key='item.no' :label="item.name" :value="item.no"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="登录名" :label-width="formLabelWidth" prop="loginName">
              <el-input v-model="userInfo.loginName" placeholder="请输入登录用户名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="学历" :label-width="formLabelWidth" prop="education">
              <el-select clearable placeholder="请选择学历" v-model="userInfo.education">
                <el-option v-for="item in Config.eduOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="密码" :label-width="formLabelWidth" prop="password">
              <el-tooltip content="密码可不填写,默认为:123456" :enterable="false" placement="top-start">
                <el-input v-model="userInfo.password" placeholder="不填默认:123456"></el-input>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="驾照类型" :label-width="formLabelWidth" prop="drivingLicence">
              <el-select clearable placeholder="请选择驾照类型" v-model="userInfo.drivingLicence">
                <el-option v-for="item in Config.drivingLicenceOptions" :key='item.optionNo' :label="item.optionName"
                  :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="性别" :label-width="formLabelWidth" prop="gender">
              <el-radio-group v-model="userInfo.gender" size='mini'>
                <el-radio-button :label="1">男</el-radio-button>
                <el-radio-button :label="0">女</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="持枪资格" :label-width="formLabelWidth" prop="holdingGun">
              <el-radio-group v-model="userInfo.holdingGun" size='mini'>
                <el-radio-button :label="true">有</el-radio-button>
                <el-radio-button :label="false">无</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="出生日期" :label-width="formLabelWidth" prop="birthDate">
              <el-date-picker v-model="userInfo.birthDate" type="date" placeholder="请选择出生日期" value-format="yyyy-MM-dd"
                :picker-options="pickerOptions" :default-value="defaultDate" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveUserInfo">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改用户dialog -->
    <el-dialog title="修改用户信息" :visible.sync="editDialogFormVisible" @close="closeEditDialog">
      <el-form :model="userInfo" :rules="userRlues" ref='editUserInfoRef'>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
              <el-input v-model="userInfo.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="组别" :label-width="formLabelWidth" prop="staffGroup">
              <el-select clearable placeholder="请选择组别" v-model="userInfo.staffGroup">
                <el-option v-for="item in Config.groupOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="管理员" :label-width="formLabelWidth" prop="manager">
              <el-radio-group v-model="userInfo.manager" size='mini'>
                <el-radio-button :label="true">是</el-radio-button>
                <el-radio-button :label="false">否</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="职务" :label-width="formLabelWidth" prop="occupation">
              <el-select clearable placeholder="请选择职务" v-model="userInfo.occupation">
                <el-option v-for="item in Config.occupationOptions" :key='item.no' :label="item.name" :value="item.no"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="登录名" :label-width="formLabelWidth" prop="loginName">
              <el-input v-model="userInfo.loginName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="学历" :label-width="formLabelWidth" prop="education">
              <el-select clearable placeholder="请选择学历" v-model="userInfo.education">
                <el-option v-for="item in Config.eduOptions" :key='item.optionNo' :label="item.optionName" :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="密码" :label-width="formLabelWidth" prop="password">
              <el-tooltip content="密码默认不展示,如果不修改密码不用填写" :enterable="false" placement="top-start">
                <el-input v-model="userInfo.password" placeholder="不修改不用填写"></el-input>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="驾照类型" :label-width="formLabelWidth" prop="drivingLicence">
              <el-select clearable placeholder="请选择驾照类型" v-model="userInfo.drivingLicence">
                <el-option v-for="item in Config.drivingLicenceOptions" :key='item.optionNo' :label="item.optionName"
                  :value="item.optionNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="性别" :label-width="formLabelWidth" prop="gender">
              <el-radio-group v-model="userInfo.gender" size='mini'>
                <el-radio-button :label="1">男</el-radio-button>
                <el-radio-button :label="0">女</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="持枪资格" :label-width="formLabelWidth" prop="holdingGun">
              <el-radio-group v-model="userInfo.holdingGun" size='mini'>
                <el-radio-button :label="true">有</el-radio-button>
                <el-radio-button :label="false">无</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="出生日期" :label-width="formLabelWidth" prop="birthDate">
              <el-date-picker v-model="userInfo.birthDate" type="date" placeholder="请选择出生日期" value-format="yyyy-MM-dd"
                :picker-options="pickerOptions" :default-value="defaultDate" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > new Date().setFullYear(new Date().getFullYear() - 18);
          }
        },
        defaultPage: 1,
        searchInfo: {
          userName: '',
          page: 1,
          pageSize: 10
        },
        pageInfo: {
          firstPage: false,
          lastPage: false,
          listResult: [],
          navigatepageNums: [],
          pageCount: 0,
          total: 0
        },
        userInfoList: [],
        userInfo: {
          staffGroup: '',
          birthDate: '',
          drivingLicence: '',
          education: '',
          gender: 1,
          holdingGun: false,
          loginName: '',
          manager: false,
          name: '',
          occupation: '',
          password: ''
        },
        defaultDate: null,
        userRlues: {
          name: [{
            required: true,
            message: "请输入姓名",
            trigger: 'blur'
          }],
          loginName: [{
              required: true,
              message: "请输入登录名",
              trigger: 'blur'
            },
            {
              min: 6,
              max: 20,
              message: '长度限制在6～20位'
            }
          ]
        },
        dialogTitle: '',
        addDialogFormVisible: false,
        editDialogFormVisible: false,
        formLabelWidth: '100px',
        occupationOptions: [{
            value: 1,
            label: "职务1"
          },
          {
            value: 2,
            label: "职务2"
          },
          {
            value: 3,
            label: "职务3"
          },
          {
            value: 4,
            label: "职务4"
          },
          {
            value: 5,
            label: "职务5"
          },
        ],
        occupationValues: {
          1: "职务1",
          2: "职务2",
          3: "职务3",
          4: "职务4",
          5: "职务5",
        },
      }
    },
    created() {
      this.getDefaultDate();
      this.getList();
    },
    methods: {
      search(){
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.getList();
      },
      handleSizeChange(val) {
        this.searchInfo.page = 1;
        this.defaultPage = 1;
        this.searchInfo.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.searchInfo.page = val;
        this.getList();
      },
      getDefaultDate() {
        this.defaultDate = new Date().setFullYear(new Date().getFullYear() - 18);
      },
      openEditDialog(euinfo) {
        this.userInfo = JSON.parse(JSON.stringify(euinfo));
        this.editDialogFormVisible = true;
      },
      closeDialog() {
        this.$refs.userInfoRef.resetFields();
        this.userInfoClear();
      },
      closeEditDialog() {
        this.$refs.editUserInfoRef.resetFields();
        this.userInfoClear();
      },
      editUserInfo() {
        this.$refs.editUserInfoRef.validate(async (valid) => {
          if (!valid)
            return;
          var {
            data: res
          } = await this.$http.post("/staff/edit", this.userInfo);
          if ("SUCCESS" != res.code) {
            this.$message.error(res.message);
            return;
          }
          this.userInfoClear();
          this.editDialogFormVisible = false;
          this.getList();
        });

      },
      saveUserInfo() {
        this.$refs.userInfoRef.validate(async (valid) => {
          if (!valid)
            return;

          var {
            data: res
          } = await this.$http.post("/staff/reg", this.userInfo);
          if ("SUCCESS" != res.code) {
            this.$message.error(res.message);
            return;
          }
          this.addDialogFormVisible = false;
          this.userInfoClear();
          this.getList();
        })
      },
      userInfoClear() {
        this.userInfo = {
          staffGroup: '',
          birthDate: '',
          drivingLicence: '',
          education: '',
          gender: 1,
          holdingGun: false,
          loginName: '',
          manager: false,
          name: '',
          occupation: '',
          password: ''
        }
      },
      delUser(data) {
        this.$confirm(
          "是否删除用户: " + data.name + " ?", "提示", {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
          var {
            data: res
          } = await this.$http.delete('/staff/delete/' + data.staffNo);

          if ('SUCCESS' != res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success("用户 " + data.name + " 已经删除");
          this.getList();
        }).catch(() => {});

      },
      async getList() {
        this.loading = true;
        try {
          var {
            data: res
          } = await this.$http.post('/staff/list', this.searchInfo);
          this.loading = false;
          if (!res.success) {
            this.$message.error(res.message);
            return;
          }
          this.pageInfo = res.result;
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
</script>

<style lang="less" scoped>
</style>
